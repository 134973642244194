import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import "./PagePrescription.css";
import questionMark from "../../assets/icons/QuestionMark.svg"; 
import cashDollar from "../../assets/icons/CashDollar.svg"; 
import deleteSVG from "../../assets/icons/Delete.svg"; 
import { fetchPrescriptions } from '../../actions';
import { LoaderA } from '../../components';

function PagePrescription(props) {
    useEffect(()=>{
        props.fetchPrescriptions();
    }, []);

    const displayPending = ()=>{
        return props.prescriptionHistory.pending.map((p, i)=><div key={i} className={`entry ${p.status}`}>
            <NavLink exact to={`/dashboard/prescription/${p.id}`} className="nav-link" activeClassName="active">
                <img src={questionMark} alt="Pending"/>
                <span>{p.date}</span>
                <span>{p.name}</span>
            </NavLink>
        </div>);
    }

    const displayOther= ()=>{
        return props.prescriptionHistory.other.map((p, i)=><div key={i} className={`entry ${p.status}`}>
            <NavLink exact to={`/dashboard/prescription/${p.id}`} className="nav-link" activeClassName="active">
                {p.status=="removed"?
                <img src={deleteSVG} alt="Removed"/>
                :
                <img src={cashDollar} alt="Paid"/>
                }
                <span>{p.date}</span>
                <span>{p.name}</span>
            </NavLink>
        </div>);
    }

    return (
        <div className='pageprescription'>
            <div className='mt-5 h3 text-center text-uppercase'>Prescriptions</div>

            {props.prescriptionHistory.isloading?<LoaderA/>:
            <div className='content'>
                {displayPending()}
                {displayOther()}
            </div>
            }

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription,
        prescriptionHistory: state.prescriptionHistory,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {fetchPrescriptions})(PagePrescription);