import React from 'react';
import { connect } from 'react-redux';
import "./PrescriptionPreview.css";


function PrescriptionPreview({prescription, auth, ...rest}) {

    const displayDrugs = ()=>{
        return prescription.drugs.map((drug, i)=>{
            return <tr key={i} className='internal'>
                <td className='nowrap'>{drug[0]}</td>
                <td className='drug'>{drug[1]}</td>
                <td className='nowrap'>{drug[2]}</td>
                <td className='nowrap'>{drug[3]}</td>
                <td className='nowrap'>{drug[4]}</td>
                <td className='nowrap'>{drug[5]}</td>
            </tr>
        });
    }
    const displayCustomDrugs = ()=>{
        return prescription.outsideDrugs.map((drug, i)=>{
            return <tr key={i}>
                <td className='nowrap'>{drug[0]}</td>
                <td className='drug'>{drug[1]}</td>
                <td className='nowrap'>{drug[2]}</td>
                <td className='nowrap'>{drug[3]}</td>
                <td className='nowrap'>{drug[4]}</td>
                <td className='nowrap'>{drug[5]}</td>
            </tr>
        });
    }
    return (
        <div className={rest.noInternal?'prescription-preview nointernal':'prescription-preview'}>
            <div className='prescription-bg'>
                <div className={auth.clinicprintformat.charAt(0)=="n"?"prescription-head displayno": "prescription-head"}>
                    <div>
                        <img src={auth.cliniclogo} width="200px" alt="Clinic Logo"/>
                    </div>
                    <div>
                        <div>{auth.clinicaddress}</div>
                        <div>
                            {auth.clinicphone} 
                            {auth.clinicphone2?" - "+auth.clinicphone2:null} 
                            {auth.clinicemail?" - "+auth.clinicemail:null}
                            {auth.clinicweb?" - "+auth.clinicweb:null}
                        </div>
                    </div>
                </div>
                <div className={auth.clinicprintformat.charAt(1)=="n"?"prescription-body displayno": "prescription-body"}>
                    <div className='prescription-date'>Date: {prescription.date}</div>

                    <div className='prescription-patient'>
                        {prescription.patienttitle}. {prescription.patientname} - {prescription.patientage}y
                    </div>

                    <div className='previous-reports'>
                        {prescription.reports.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                    </div>

                    <div className='spot-tests'>
                        {prescription.spotTests.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                    </div>

                    <div className='drugs-table'>
                        <table>
                            <thead></thead>
                            <tbody>
                                {displayDrugs()}
                                {displayCustomDrugs()}
                            </tbody>
                        </table>
                    </div>

                    <div className='nextvisit'>
                        {prescription.tests.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                    </div>

                    <div className='note'>
                        {prescription.note}
                    </div>

                    <div className='seal'>
                        <div className='docname'>{prescription.doctorname}</div>
                        <div>{prescription.doctorseal.map((t, i)=><div key={i}>{t}</div>)}</div>
                        <div>Reg. No. {prescription.doctorregno}</div>
                    </div>
                </div>
                <div className={auth.clinicprintformat.charAt(2)=="n"?"prescription-footer displayno": "prescription-footer"}>
                    <div>
                        <div>{auth.clinicaddress}</div>
                        <div>
                            {auth.clinicphone} 
                            {auth.clinicphone2?" - "+auth.clinicphone2:null} 
                            {auth.clinicemail?" - "+auth.clinicemail:null}
                            {auth.clinicweb?" - "+auth.clinicweb:null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps, {})(PrescriptionPreview);
