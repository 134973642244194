import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { updatePatient } from '../../actions';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./OnePatient.css";

function OnePatient(props) {
    const [id, setId] = useState("");
    const [title, setTitle] = useState("Mr");
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(()=>{
        setId(props.patient.id);
        setTitle(props.patient.title);
        setName(props.patient.name);
        setAge(props.patient.age);
        setPhone(props.patient.phone);
    }, [props.patient]);

    const handleUpdatePatientDetails = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            // submit changes
            props.updatePatient({
                id: id,
                title: title,
                name: name,
                age: age,
                phone: phone,
            });
        }
    }

    return (
        <div className='onepatient'>

            <h1 className='mt-5 h3 text-center text-uppercase'>Patient Details</h1>

            <div className='border rounded p-4 form mb-5'>
                <Form onSubmit={handleUpdatePatientDetails}>
                    <Form.Group className="entry">
                        <Form.Label>Title</Form.Label>
                        <div className='custominput'>
                            <Form.Select aria-label="Select patient title" value={title} onChange={(e)=>{setTitle(e.target.value)}}>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Dr">Dr</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>Patient Name</Form.Label>
                        <Form.Control type="text" placeholder="" maxLength={40} required value={name} onChange={(e)=>{setName(e.target.value)}}/>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>Age (yrs)</Form.Label>
                        <Form.Control required type="number" placeholder="Age" min="0" max="130" value={age} onChange={(e)=>{setAge(e.target.value)}}/>
                    </Form.Group> 
                    <Form.Group className="entry">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control required type="text" placeholder="Phone" pattern="[0-9]{10}" maxLength={10} value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                    </Form.Group> 
                    <div className='mt-4'>
                        <Button variant="primary" type="submit">Update Patient Details</Button>
                    </div>
                </Form>

                <NavLink exact to={`/dashboard/`} className="mt-2 d-block">
                    Back To Patient Management
                </NavLink>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patient: state.patient
    }
}

export default connect(mapStateToProps, {updatePatient})(OnePatient);