import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import "./DashboardRefill.css";
import { addRefill as addRefillBackend, fetchRefill, setRefillVariantId } from '../../actions';
import { LoaderA } from '../../components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

function DashboardRefill(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [refillHistory, setRefillHistory] = useState([]);
    const [name, setName] = useState("");
    const [unit, setUnit] = useState("mg");
    const [dose, setDose] = useState("");
    const [available, setAvailable] = useState("");

    const [quantity, setQuantity] = useState("");
    const [buyPrice, setBuyPrice] = useState("");
    const [sellPrice, setSellPrice] = useState("");
    const [isCorrective, setIsCorrective] = useState(false);

    useEffect(()=>{
        setIsLoading(true);
        if (props.inventory.refillVariantId!==""){
            props.fetchRefill(props.inventory.refillVariantId);
        }
        setIsLoading(false);
    }, [props.inventory.refillVariantId]);

    useEffect(()=>{
        if (props.inventory.refillVariant!=null){
            setRefillHistory(props.inventory.refillList.slice().reverse());

            setName(props.inventory.refillVariant.name);
            setUnit(props.inventory.refillVariant.unit);
            setDose(props.inventory.refillVariant.dose);
            setAvailable(props.inventory.refillVariant.Available);

        }
    }, [props.inventory.refillVariant]);

    const addRefill = (e)=>{
        e.preventDefault();
        props.addRefillBackend({
            variantId: props.inventory.refillVariantId,
            isCorrective: isCorrective,
            quantity: quantity,
            buyPrice: buyPrice,
            sellPrice: sellPrice,
        });
        setQuantity(""); setBuyPrice(""); setSellPrice(""); setIsCorrective(false);
    }

    const cancelEdit = ()=>{
        setQuantity(""); setBuyPrice(""); setSellPrice(""); setIsCorrective(false);
        props.setRefillVariantId("", false);
    }

    const displayRefillHistory = ()=>{
        const rows = [];
        refillHistory.forEach((refill, i)=>{
            rows.push(
                <tr key={i}>
                    <td>{refill[0]}{refill[3]?<Badge pill bg="success">(C)</Badge>:""}</td>
                    <td>{refill[1]}</td>
                    <td>{refill[2]}</td>
                </tr>
            );
        });
        return rows;
    }

    if (!props.inventory.showRefill){
        return null;
    }
    if (isLoading){
        return <LoaderA/>
    }
    return (
        <div>
            <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form onSubmit={addRefill} className='refillModal'>
                    <Modal.Header>
                        <Modal.Title>
                            <div>Refill</div>
                            <p className='h6'>{name} - {dose+unit} | Available stock {available}</p>
                            
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {refillHistory.length!==0?<div className='mb-5'>
                            <div className='h5'>Refill History</div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Quantity</th>
                                        <th>Buying Price</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayRefillHistory()}
                                </tbody>
                            </Table>
                            </div>
                            :null}

                            <div className='h5'>Add Stock</div>

                            <Form.Group className="mb-3 pe-3" controlId="corrective">
                                <Form.Check label="Correcting a mistake" value={isCorrective} onChange={(e)=>{ setIsCorrective(!isCorrective); }}/>
                            </Form.Group>

                            <div className="d-md-flex flex-wrap">
                                <Form.Group className="mb-3 pe-3" controlId="warning">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" placeholder="Quantity" min={isCorrective?"":"0"} required 
                                    value={quantity} onChange={(e)=>{setQuantity(e.target.value)}}/>
                                </Form.Group>

                                {!isCorrective?<>
                                <Form.Group className="mb-3 pe-3" controlId="buyprice">
                                    <Form.Label>Buying price (Rs)</Form.Label>
                                    <Form.Control type="number" placeholder="Buying price" min="0" required step="0.01"
                                    value={buyPrice} onChange={(e)=>{setBuyPrice(e.target.value)}}/>
                                </Form.Group>
                                <Form.Group className="mb-3 pe-3" controlId="sellprice">
                                    <Form.Label>Selling price (Rs)</Form.Label>
                                    <Form.Control type="number" placeholder="Selling price" min="0" required step="0.01"
                                    value={sellPrice} onChange={(e)=>{setSellPrice(e.target.value)}}/>
                                </Form.Group>
                                </>:null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancelEdit}>Cancel</Button>
                        <Button variant="primary" type="submit">Add Stock</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        inventory: state.inventory
    }
}

export default connect(mapStateToProps, {fetchRefill, setRefillVariantId, addRefillBackend})(DashboardRefill);
