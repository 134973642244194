import { SET_PRESCRIPTION, RESET_PRESCRIPTION } from "./types";

const initState = {
  open: false,
  possible_drugs: [],
  isFreeze: false,

  prescriptionId: "",
  isComplete: false,
  status: "pending",
  drugs: [], 
  outsideDrugs: [], 
  procedures: [],
  reports: [],
  spotTests: [],
  tests: [],
  note: "",
  drugSellingPrice: 0,
  totalcharge: 0,
  date: "",
  doctorname: "",
  doctorseal: [],
  doctorregno: "",
  doctorcharge: 0,
  patienttitle: "",
  patientname: "",
  patientage: "",
  cashier: "", 
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_PRESCRIPTION:
      return {
        ...state,
        ...action.payload
      };
    case RESET_PRESCRIPTION:
      return {
        ...state,
        ...initState
      };
    default:
      return state;
  }
}
