import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DashboardUserSummary, DashboardSideNav, DashboardPrescriptionHistoryNav, OnePrescription, PagePrescription, OnePatient, OneUser } from '../../containers';
import { DashboardPatientManagement, DashboardInventoryManagement, DashboardClinicSettings, DashboardUserManagement, DashboardAnalytics } from "../index";
import { refreshToken, fetchUser } from '../../actions';
import { Route } from "react-router-dom";
import "./Dashboard.css";

import Container from 'react-bootstrap/Container';
import { LoaderA } from '../../components';

function Dashboard(props) {
    useEffect(()=>{
        props.refreshToken();
        props.fetchUser();
    }, []);

    if (props.auth.id===""){
        return <LoaderA/>
    }else{
        return (
            <div className='dashboard' style={{minHeight: "600px"}}>
                <DashboardUserSummary/>
                <DashboardSideNav/> 
                <DashboardPrescriptionHistoryNav/>

                <div className='container dashboard-page'>
                    <Route exact path="/dashboard/"><DashboardPatientManagement/></Route>
                    <Route path="/dashboard/inventorymanagement"><DashboardInventoryManagement/></Route>
                    <Route path="/dashboard/clinic"><DashboardClinicSettings/></Route>
                    <Route path="/dashboard/prescriptions"><PagePrescription/></Route>
                    <Route path="/dashboard/usermanagement"><DashboardUserManagement/></Route>
                    <Route path="/dashboard/analytics"><DashboardAnalytics/></Route>
                    <Route path="/dashboard/prescription/:id" component={OnePrescription}></Route>
                    <Route path="/dashboard/patient/:id" component={OnePatient}></Route>
                    <Route path="/dashboard/user" component={OneUser}></Route>
                    {/* <Route path="/dashboard/financemanagement"></Route> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {refreshToken, fetchUser})(Dashboard);
