import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import "./Loader.css";

function Loader(props) {
    return <div className={props.common.comp_loading?"comp_loader":"comp_loader comp_hide"}>
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(Loader);