import { SET_PRESCRIPTION, RESET_PRESCRIPTION } from "./types";
import axios from "axios";
import { setLoading, showToast } from "../../App";

export function buildPrescription(data){
    return async function(dispatch, getState) {
        const { patient } = getState();
        setLoading(true);

        const payload = await axios.post("/backend/prescription/build_prescription/", {...data, patientId: patient.id})
        .then((res)=>{
            showToast({ show_toast: true, toast_message: "Prescription built.<br/>Drugs allocated." });
            const resData = {...res.data};
            const drugsArray = [];
            for(const i in resData["drugs"]){
                drugsArray.push(JSON.parse(resData["drugs"][i]));
            }
            const odrugsArray = [];
            for(const i in resData["outsideDrugs"]){
                odrugsArray.push(JSON.parse(resData["outsideDrugs"][i]));
            }
            const procedureArray = [];
            for(const i in resData["procedures"]){
                procedureArray.push(JSON.parse(resData["procedures"][i]));
            }
            resData["drugs"] = drugsArray;
            resData["outsideDrugs"] = odrugsArray;
            resData["procedures"] = procedureArray;
            return {isFreeze: true, open: false, ...resData};
        })
        .catch(()=>{
            showToast({ show_toast: true, toast_message: "Internal server error", show_error: true });
            return {isFreeze: false, open: true};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_PRESCRIPTION,
            payload: payload,
        });
    }
}

export async function searchPossibleDrugsNoRedux(data){
    if (data["drug"].trim()!==""){ } else { return []; }
    const payload = await axios.post("/backend/prescription/search_drug/", data)
    .then((res)=>{
        return res.data.data;
    })
    .catch(()=>{
        return [];
    });
    return payload;
}

export function setPrescription(data){
    return async function(dispatch) {
        dispatch({
            type: SET_PRESCRIPTION,
            payload: data,
        });
    }
}

export function openPrescription(data=false){
    return async function(dispatch, getState) {
        const { patient, prescription } = getState();
        const payload = {};
        if (patient.selected) {
            payload["open"] = !prescription.open;
        } else {
            payload["open"] = false;
        }
        if (data){ payload["open"] = true; }
        dispatch({
            type: SET_PRESCRIPTION,
            payload: payload,
        });
    }
}

export function paid(data){
    return async function(dispatch, getState) {
        const { prescription } = getState();
        setLoading(true);

        if (data.total==="NaN" && data.method!=="removed"){ 
            showToast({ show_toast: true, toast_message: "Please use valid amounts.", show_error: true });
            setLoading(false);
            return; 
        }

        const payload = await axios.post("/backend/prescription/change_status_prescription/", {...data, prescriptionId: prescription.prescriptionId})
        .then((res)=>{
            if (res.data.status=="fpaid"){
                showToast({ show_toast: true, toast_message: "Prescription status updated to Paid." });
                return {...res.data};
            } else if (res.data.status=="spaid"){
                showToast({ show_toast: true, toast_message: "Prescription status updated to Paid for Service.<br/>Stock released." });
                return {...res.data};
            } else if (res.data.status=="removed"){
                showToast({ show_toast: true, toast_message: "Prescription discarded.<br/>Stock released." });
                return {...res.data};
            }
        })
        .catch(()=>{
            showToast({ show_toast: true, toast_message: "Internal server error. Please try again.", show_error: true });
            return {};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_PRESCRIPTION,
            payload: payload,
        });
    }
}

export function resetPrescription(){
    return async function(dispatch) {
        dispatch({
            type: RESET_PRESCRIPTION,
            payload: {},
        });
    }
}

export function fetchPrescription(id){
    return async function(dispatch) {
        setLoading(true);
        const payload = await axios.get("/backend/prescription/fetch_prescription/"+id)
        .then((res)=>{
            const resData = {...res.data};
            const drugsArray = [];
            for(const i in resData["drugs"]){
                drugsArray.push(JSON.parse(resData["drugs"][i]));
            }
            const odrugsArray = [];
            for(const i in resData["outsideDrugs"]){
                odrugsArray.push(JSON.parse(resData["outsideDrugs"][i]));
            }
            const procedureArray = [];
            for(const i in resData["procedures"]){
                procedureArray.push(JSON.parse(resData["procedures"][i]));
            }
            resData["drugs"] = drugsArray;
            resData["outsideDrugs"] = odrugsArray;
            resData["procedures"] = procedureArray;
            return {...resData};
        })
        .catch(()=>{
            return {};
        })
        .finally(()=>{
            setLoading(false);
        })

        dispatch({
            type: SET_PRESCRIPTION,
            payload: payload,
        });
    }
}

export async function searchPossibleWordsNoRedux(data){
    if (data["customText"].trim()===""){ return []; }
    const payload = await axios.post("/backend/prescription/search_words/", data)
    .then((res)=>{
        return res.data.data;
    })
    .catch(()=>{
        return [];
    });
    return payload;
}

export async function searchPossibleProceduressNoRedux(data){
    if (data["name"].trim()===""){ return []; }
    const payload = await axios.post("/backend/prescription/search_procedures/", data)
    .then((res)=>{
        return res.data.data;
    })
    .catch(()=>{
        return [];
    });
    return payload;
}

export async function searchPossibleCustomDrugsNoRedux(data){
    if (data["drug"].trim()===""){ return {drugs: [], dose: []}; }
    const payload = await axios.post("/backend/prescription/search_custom_drugs/", data)
    .then((res)=>{
        return res.data;
    })
    .catch(()=>{
        return {drugs: [], dose: []};
    });
    return payload;
}
