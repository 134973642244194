import { SET_COMMON } from "./types";

const initState = {
    comp_loading: false,
    page_loading: false,

    show_toast: false,
    toast_message: "",
    show_error: false,
    autohide: true,

    patientNo: 0,
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_COMMON:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
