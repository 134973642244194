import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { searchPossibleProceduressNoRedux } from '../../actions';

function ProcedureLine(props) {
    const [procedure, setProcedure] = useState({
        name: "",
        charge: "",
        procedureId: 0,
    });
    const [suggestions, setSuggestions] = useState([]);

    const updateProcedure = (data)=>{
        const newProcedure = {...procedure, ...data, procedureId: props.procedureId};
        setProcedure(newProcedure);
        props.onChange(newProcedure);
    }

    useEffect(()=>{
        setProcedure({...procedure, name: "Procedure "+parseInt(props.procedureId+1)});
    }, []);

    useEffect(()=>{
        const delayDebounceFn = setTimeout(async () => {
            const payload = await searchPossibleProceduressNoRedux({name: procedure.name});
            setSuggestions(payload);
        }, 600);
        return ()=> clearTimeout(delayDebounceFn);
    }, [procedure.name]);

    return (
        <div className='price-line procedures'>
            <div>
                <Form.Control list={"suggestion"+props.procedureId} size="sm" type="text" value={procedure.name} onChange={(e)=>{updateProcedure({name: e.target.value})}} />
                <datalist id={"suggestion"+props.procedureId}>
                    {suggestions.map((item, i)=><option key={i} value={item}>{item}</option>)}
                </datalist>
            </div>
            <div className='amount'>
                <Form.Control size="sm" type="number" min="0" value={procedure.charge} onChange={(e)=>{updateProcedure({charge: e.target.value.trim()})}} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(ProcedureLine);
