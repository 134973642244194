import React, { useState } from 'react';
import { connect } from 'react-redux';
import "./DashboardPatientHistory.css";
import { addDrug as addDrugBackend, setInventory, setPrescriptionHistory } from '../../actions';
import PrescriptionHistoryPreview from './PrescriptionHistoryPreview';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';


function DashboardPatientHistory(props) {
    return (
        <div>
            <Modal show={props.prescriptionHistory.patientHistoryVisible} onHide={()=>{props.setPrescriptionHistory({patientHistoryVisible: false});}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <PrescriptionHistoryPreview/>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        prescriptionHistory: state.prescriptionHistory
    }
}

export default connect(mapStateToProps, {addDrugBackend, setInventory, setPrescriptionHistory})(DashboardPatientHistory);
