import React from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import { PublicNavbar } from '../../containers';

function PublicRoute({children, ...rest}) {
    return (
        <>
            <PublicNavbar/>
            <Route {...rest}>{children}</Route>
        </>
    )
}

export default connect(null, {})(PublicRoute);
