import { SET_UI } from "./types";

export function setUI(payload){
    return async function(dispatch) { 
        dispatch({
            type: SET_UI,
            payload: payload,
        });
    }
}
