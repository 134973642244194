import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import "./UserCard.css";
import Profile from "../../assets/icons/Profile.svg";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { updatePermissionNoRedux, resetPasswordNoRedux, removeUserNoRedux } from '../../actions';

function UserCard(props) {
    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("ABCD1234#");
    const [permission, setPermission] = useState(["t", "t", "t", "t", "t", 't']);
    const [isDeleted, setIsDeleted] = useState("Delete User");

    useEffect(()=>{
        setUsername(props.profile.username);
        setRole(props.profile.role);
        setId(props.profile.id);
        setPermission(props.profile.permission.split(""));
        setIsDeleted(props.profile.isDeleted);
        if (props.profile.role!=="doctor"){
            const per = props.profile.permission.split("");
            per[1] = "f";
            setPermission(per);
        }
    }, []);

    const updatePermission = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            updatePermissionNoRedux({
                id: id,
                permission: permission.join("")
            });
        }
    }

    const resetPassword = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            resetPasswordNoRedux({
                id: id,
                password: password
            });
        }
    }

    const removeUser = ()=>{
        removeUserNoRedux({id: id});
        setIsDeleted("Deleted");
    }

    const handlePermission = (index)=>{
        const per = [...permission]
        per[index] = `${per[index]==="t"?"f":"t"}`;
        setPermission(per);
    }

    return (
        <div className='usercard border mt-3'>
            <div className='text-center'>
                <img src={Profile} height={100} alt="Profile Icon"/>
            </div>
            <div>
                <div className='px-2'>
                    <Form onSubmit={updatePermission}>
                        <Form.Group className="mb-3" controlId={"username"+props.index}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control size="sm" type="text" maxLength={40} required value={username} onChange={(e)=>{setUsername(e.target.value.trim())}} disabled={true}/>
                        </Form.Group>

                        {isDeleted!=="Deleted"?
                        <div>
                            <Form.Group className="mb-3" controlId={"role"+props.index}>
                                <Form.Label>Role</Form.Label>
                                <Form.Select size="sm" aria-label="select a role" value={role} onChange={(e)=>{setRole(e.target.value)}} disabled={true}>
                                    <option value="doctor">Doctor</option>
                                    <option value="assistant">Assistant</option>
                                    <option value="manager">Manager</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Permissions</Form.Label>
                                <Form.Check type="checkbox" id={"user"+props.index} label="User Management" disabled={props.index===0} checked={permission[0]==="t"} onChange={()=>handlePermission(0)}/>
                                <Form.Check type="checkbox" id={"prescription"+props.index} label="Prescription Writing" disabled={props.index===0 || role!=="doctor"} checked={permission[1]==="t"} onChange={()=>handlePermission(1)}/>
                                <Form.Check type="checkbox" id={"inventory"+props.index} label="Inventory" disabled={props.index===0} checked={permission[2]==="t"} onChange={()=>handlePermission(2)}/>
                                <Form.Check type="checkbox" id={"billing"+props.index} label="Billing" disabled={props.index===0} checked={permission[3]==="t"} onChange={()=>handlePermission(3)}/>
                                <Form.Check type="checkbox" id={"clinic"+props.index} label="Clinic Settings" disabled={props.index===0} checked={permission[4]==="t"} onChange={()=>handlePermission(4)}/>
                                <Form.Check type="checkbox" id={"analytics"+props.index} label="Analytics" disabled={props.index===0} checked={permission[5]==="t"} onChange={()=>handlePermission(5)}/>
                            </Form.Group>

                            <div className='text-center pb-3'>
                                {props.index===0?
                                <Badge bg="secondary" className='px-4'>You</Badge>
                                :
                                <Button size="sm" variant="primary" type="submit">Update Permission</Button>
                                }
                            </div>
                        </div>
                        :null}
                    </Form>

                    {props.index!==0?
                    <Form onSubmit={resetPassword}>
                        {isDeleted!=="Deleted"?
                        <div>
                            <Form.Group className="mb-3" controlId={"password"+props.index}>
                                <Form.Label>Temporary Password</Form.Label>
                                <Form.Select size="sm" aria-label="select a password" value={password} onChange={(e)=>{setPassword(e.target.value)}}>
                                    <option value="ABCD1234#">ABCD1234#</option>
                                    <option value="TEMPORARY@88">TEMPORARY@88</option>
                                    <option value="ABCD@1234">ABCD@1234</option>
                                </Form.Select>
                            </Form.Group>
                            <div className='text-center pb-3'>
                                <Button size="sm" variant="primary" type="submit">Reset Password</Button>
                            </div>
                        </div>
                        :null}
                        <div className='text-center pb-3'>
                            <Button size="sm" variant="danger" type="button" disabled={isDeleted=="Deleted"} onClick={removeUser}>{isDeleted}</Button>
                        </div>
                    </Form>
                    :null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(UserCard);
