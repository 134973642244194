import { SET_USER } from "./types";

const initState = {
  users: [],
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
