import React from 'react';

function Register() {
    return (
        <div>
            Register page
        </div>
    )
}

export default Register;
