import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { changeUserPasswordNoRedux } from '../../actions';

function NewPassword(props) {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [error, setError] = useState("");

    useEffect(()=>{
        setOldPassword("");
        setNewPassword("");
        setNewPassword2("");
        setError("");
    }, [props.show]);

    const handleSubmit = async (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            const result = await changeUserPasswordNoRedux({
                oldPassword: oldPassword,
                newPassword: newPassword,
                newPassword2: newPassword2,
            });
            if (result.error!==undefined){ return setError(result.error);} 
            setError(""); props.setShow(false);
        }
    }

    return (
        <div>
            <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>
                            Change Password
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="oldpass">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="text" maxLength={50} required value={oldPassword} onChange={(e)=>{setOldPassword(e.target.value.trim())}}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newpass">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="text" maxLength={50} required value={newPassword} onChange={(e)=>{setNewPassword(e.target.value.trim())}}/>
                            <Form.Text className="text-muted">
                                Your password can’t be too similar to your other personal information.
                                <br/>
                                Your password must contain at least 8 characters.
                                <br/>
                                Your password can’t be a commonly used password.
                                <br/>
                                Your password can’t be entirely numeric.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newpass2">
                            <Form.Label>New Password (Again)</Form.Label>
                            <Form.Control type="text" maxLength={50} required value={newPassword2} onChange={(e)=>{setNewPassword2(e.target.value.trim())}}/>
                        </Form.Group>

                        <div className='text-danger'>{error}</div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="secondary" onClick={()=>props.setShow(false)}>Cancel</Button>
                        <Button variant="primary" type="submit">Change</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(NewPassword);