import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import tick from '../../assets/icons/tick.svg';
import cancel from '../../assets/icons/cancel.svg';
import { INTAKE_SELECTOR } from './Constants';
import { searchPossibleCustomDrugsNoRedux } from '../../actions';


function CustomDrugItem(props) {
    const [route, setRoute] = useState("O");
    const [nature, setNature] = useState("pill");
    const [drug, setDrug] = useState("");
    const [dose, setDose] = useState("");
    const [doseUnit, setDoseUnit] = useState("");
    const [intake, setIntake] = useState("1 tab");
    const [meal, setMeal] = useState("");
    const [frequency, setFrequency] = useState("1 daily");
    const [pillCount, setPillCount] = useState("");
    const [duration, setDuration] = useState("");
    const [durationUnit, setDurationUnit] = useState("1 days");

    const [isValid, setIsValid] = useState(false);
    const [suggestionsDrug, setSuggestionsDrug] = useState([""]);
    const [suggestionsDose, setSuggestionsDose] = useState([""]);

    useEffect(()=>{
        if(checkValidity()){
            props.onChange({
                drugIndex: props.drugIndex,
                route: route, nature: nature, name: drug, dose: dose, unit: doseUnit, intake: intake, meal: meal, 
                frequency: frequency, pillCount: pillCount, duration: duration, durationUnit: durationUnit,
                requiredCount: getRequredPillCount(),
            });
        }else{
            props.onChange({
                drugIndex: props.drugIndex
            });
        };
    }, [route, drug, dose, doseUnit, intake, meal, frequency, pillCount, duration, durationUnit]);

    const checkValidity = ()=>{
        if (drug.trim()===""){ setIsValid(false); return false; }
        if (dose===""){ setIsValid(false); return false; }
        if (intake===""){ setIsValid(false); return false; }
        if (duration===""){ setIsValid(false); return false; }
        if (frequency==="sos" || frequency==="prn"){
            if (pillCount===""){ setIsValid(false); return false; }
        }
        setIsValid(true); return true;
    }

    const getRequredPillCount = ()=>{
        var requredPillCount = 1;
        if (frequency==="sos" || frequency==="prn"){
            requredPillCount = pillCount;
        } else if (nature!=="pill"){
            requredPillCount=1;
        } else {
            const intakeCount = intake.split(" ")[0];
            const freq=frequency.split(" ")[0];
            const dayCount = duration * durationUnit.split(" ")[0];
            requredPillCount = dayCount*freq*intakeCount;
        }
        return Math.ceil(requredPillCount);
    }

    const handleNatureChange = (value)=>{
        setNature(value);
        switch (value) {
            case "pill":
                setIntake("1 tab"); setDose("");
                break;
            case "gel":
                setIntake("gel"); setDose("No");
                break;
            default:
                setIntake(""); setDose("No");
                break;
        }
    }

    const setIntakeSelector = ()=>{
        switch (nature) {
            case "pill":
                return <Form.Select size="sm" aria-label="Intake" value={intake} onChange={(e)=>{setIntake(e.target.value)}} required>
                            {INTAKE_SELECTOR.map((option, i)=><option key={i} value={option[0]}>{option[1]}</option>)}
                        </Form.Select>;
            case "syrup":
                return <Form.Control size="sm" type="text" maxLength={8} style={{maxWidth: "100px"}} placeholder="xx ml" value={intake} onChange={(e)=>{setIntake(e.target.value.trim())}} required/>;
            case "drop":
                return <Form.Control size="sm" type="text" maxLength={8} style={{maxWidth: "100px"}} placeholder="xx drops" value={intake} onChange={(e)=>{setIntake(e.target.value.trim())}} required/>;
            case "gel":
                return;
            default:
                return null;
        }
    }

    useEffect(()=>{
        const delayDebounceFn = setTimeout(async () => {
            const payload = await searchPossibleCustomDrugsNoRedux({drug: drug, nature: nature});
            setSuggestionsDrug(payload.drugs);
            setSuggestionsDose(payload.dose);
        }, 600);
        return ()=> clearTimeout(delayDebounceFn);
    }, [drug, nature]);

    return (
        <div className='drug-item'>
            <div className='d-flex flex-wrap'>
                <Form.Group>
                    <Form.Select size="sm" aria-label="Route" value={route} onChange={(e)=>{setRoute(e.target.value)}} required>
                        <option value="O">O</option>
                        <option value="S/Y">S/Y</option>
                        <option value="S/L">S/L</option>
                        <option value="PR">PR</option>
                        <option value="S/C">S/C</option>
                        <option value="IM">IM</option>
                        <option value="IV">IV</option>
                        <option value="TP">TP</option>
                        <option value="G">G</option>
                        <option value="Neb">Neb</option>
                        <option value="MDI">MDI</option>
                        <option value="DPI">DPI</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group>
                    <Form.Select size="sm" aria-label="Route" value={nature} onChange={(e)=>{handleNatureChange(e.target.value)}} required>
                        <option value="pill">Pills</option>
                        <option value="drop">Drop</option>
                        <option value="syrup">Syrup</option>
                        <option value="gel">Gel</option>
                    </Form.Select>
                </Form.Group>
                
                <Form.Group className='position-relative drug-search drug-name'>
                    <Form.Control list={"suggestion_drug"+props.which+props.drugIndex} size="sm" type="text" placeholder="Drug name" 
                    value={drug} onChange={(e)=>{setDrug(e.target.value)}}/>
                    <datalist id={"suggestion_drug"+props.which+props.drugIndex}>
                        {suggestionsDrug.map((item, i)=><option key={i} value={item}>{item}</option>)}
                    </datalist>
                </Form.Group>
                
                {nature==="pill"?
                <Form.Group className='drug-dose'>
                    <Form.Control list={"suggestion_dose"+props.which+props.drugIndex} size="sm" type="text" min="0" placeholder="Dose" 
                    value={dose} onChange={(e)=>{setDose(e.target.value)}}/>
                    <datalist id={"suggestion_dose"+props.which+props.drugIndex}>
                        {suggestionsDose.map((item, i)=><option key={i} value={item}>{item}</option>)}
                    </datalist>
                </Form.Group>
                :null}

                {/* {nature==="pill"?
                <Form.Group>
                    <Form.Select size="sm" aria-label="Dose Unit" value={doseUnit} onChange={(e)=>{setDoseUnit(e.target.value)}} required>
                        <option value="mg">mg</option>
                        <option value="g">g</option>
                        <option value="µg">µg</option>
                        <option value="IU">IU</option>
                        <option value="U">U</option>
                    </Form.Select>
                </Form.Group>
                :null} */}


                <Form.Group>
                    {setIntakeSelector()}
                </Form.Group>

                <Form.Group>
                    <Form.Select size="sm" aria-label="Meal" value={meal} onChange={(e)=>{setMeal(e.target.value)}} required>
                        <option value="blank"></option>
                        <option value="AC">AC</option>
                        <option value="PO">PO</option>
                    </Form.Select>
                </Form.Group>

                {frequency==="sos" || frequency==="prn" ?
                <Form.Group className='pill-count' style={{marginRight: "-5px"}}>
                    <Form.Control size="sm" type="number" min="1" placeholder="Pills count" value={pillCount} onChange={(e)=>{setPillCount(e.target.value.trim())}} required/>
                </Form.Group>
                :null}

                <Form.Group>
                    <Form.Select size="sm" aria-label="Frequency" value={frequency} onChange={(e)=>{setFrequency(e.target.value)}}>
                        <option value="1 daily">daily</option>
                        <option value="3 tds">tds</option>
                        <option value="2 bd">bd</option>
                        <option value="4 qid">qid</option>
                        <option value="sos">sos</option>
                        <option value="prn">prn</option>
                        <option value="6 q4h">q4h</option>
                        <option value="4 q6h">q6h</option>
                        <option value="3 q8h">q8h</option>
                        <option value="12 q2h">q2h</option>
                        <option value="2 q12h">q12h</option>
                        <option value="0.5 qod">qod</option>
                        <option value="1 qam">qam</option>
                        <option value="1 qpm">qpm</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group style={{marginRight: "-5px", maxWidth: "100px"}}>
                    <Form.Control size="sm" type="number" min="1" placeholder="Duration" value={duration} onChange={(e)=>{setDuration(e.target.value.trim())}} required/>
                </Form.Group>

                <Form.Group>
                    <Form.Select size="sm" aria-label="Duration" value={durationUnit} onChange={(e)=>{setDurationUnit(e.target.value)}} required>
                        <option value="1 days">days</option>
                        <option value="7 weeks">weeks</option>
                        <option value="30 months">months</option>
                    </Form.Select>
                </Form.Group>

                {isValid?<img className='feed' src={tick} alt="Correct" height={"31px"}/>:<img className='feed' src={cancel} alt="Error" height={"31px"}/>}
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription
    }
}

export default connect(mapStateToProps, {})(CustomDrugItem);