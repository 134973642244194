import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Skeleton } from '../../components';
import { logout } from '../../actions';
import "./DashboardUserSummary.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function DashboardUserSummary(props) {
    if (props.auth.role!==""){
        return (
            <Container fluid className="dashboard-user-summary border-bottom">
                <Row className='bg-dark'>
                    <Col lg="2">
                        <div className='d-flex justify-content-center align-items-center' style={{height: "100%"}}>
                        <NavLink to="/dashboard" className="text-reset text-decoration-none"><h1 className='h5'>MediCenter.LK</h1></NavLink>
                        </div>
                    </Col>
                    <Col lg="8" className='border border-bottom-0'></Col>
                    <Col lg="2">
                        <div className='d-flex justify-content-center align-items-center py-2'>
                            <div className='me-3'>
                                <i className="fa-solid fa-user fa-2xl"></i>
                            </div> 
                            <div>
                                <div>{props.auth.firstname} {props.auth.lastname}</div>
                                <div>{props.auth.role}</div>
                                <NavLink exact to="/dashboard/user">
                                    <Button className='mt-1 me-2' size="sm" variant="outline-primary">Edit</Button>
                                </NavLink>
                                <Button className='mt-1' size="sm" variant="outline-danger" onClick={props.logout}>Logout</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return <Skeleton />
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        ui: state.ui
    }
}

export default connect(mapStateToProps, {logout})(DashboardUserSummary);