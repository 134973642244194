import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { DashboardPatientAdmissionSelection, DashboardPrescription, DashboardBill } from '../../containers';
import { resetPatient, resetPrescription, nextPatient } from '../../actions';

function DashboardPatientManagement(props) {

    useEffect(()=>{
        props.resetPatient();
        props.resetPrescription();
        props.nextPatient();
    }, []);


    if (props.auth.role==="Doctor" && props.auth.firstname==="" && props.auth.doctorregno==="" || props.auth.role!=="Doctor" && props.auth.firstname===""){
        return <Redirect to={{ 
            pathname: "/dashboard/user/"
        }}/>
    } else {
        return (
            <>
                <div>
                    <h1 className='mt-5 h3 text-center text-uppercase'>Patient Management</h1>
                </div>

                <DashboardPatientAdmissionSelection key={"admi"+props.common.patientNo}/>

                {props.auth.permission[1]==="t"?
                <DashboardPrescription key={"pres"+props.common.patientNo}/>
                :null}

                {props.auth.permission[1]==="t"?
                <DashboardBill key={"bill"+props.common.patientNo}/>
                :null}
                
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        common: state.common
    }
}

export default connect(mapStateToProps, {resetPatient, resetPrescription, nextPatient})(DashboardPatientManagement);
