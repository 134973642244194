import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./OneUser.css";
import { updateUser } from '../../actions';
import NewPassword from "./NewPassword";

function OneUser(props) {
    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [doctorregno, setDoctorregno] = useState("");
    const [doctorname, setDoctorname] = useState("");
    const [doctorseal, setDoctorseal] = useState("");

    const [show, setShow] = useState(false);


    useEffect(()=>{
        setId(props.auth.id);
        setUsername(props.auth.username);
        setFirstname(props.auth.firstname);
        setLastname(props.auth.lastname);
        setEmail(props.auth.email);
        if (props.auth.role==="Doctor"){
            setDoctorregno(props.auth.doctorregno);
            setDoctorname(props.auth.doctorname);
            setDoctorseal(props.auth.doctorseal.join("\n"));
        }
    }, [props.auth]);

    const handleUpdateUserDetails = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            // submit changes
            props.updateUser({
                id: id,
                role: props.auth.role,
                username: username,
                firstname: firstname,
                lastname: lastname,
                email: email,
                doctorregno: doctorregno,
                doctorname: doctorname,
                doctorseal: doctorseal,
            });
        }
    }

    return (
        <div className='oneuser'>

            <h1 className='mt-5 h3 text-center text-uppercase'>User Details</h1>

            <NewPassword show={show} setShow={setShow}/>

            <div className='border rounded p-4 form mb-5'>
                <Form onSubmit={handleUpdateUserDetails}>
                    <Form.Group className="entry">
                        <Form.Label>Username for Login</Form.Label>
                        <div className='custominput'>
                            <Form.Control type="text" placeholder="" maxLength={40} required value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
                            <div className='text-danger'>{props.auth.error}</div>
                            <Form.Text className="text-muted">Username must be an unique name which is used to login to the system.</Form.Text>
                            <div>
                                <Button style={{padding: "0"}} size="sm" variant="link" onClick={()=>setShow(true)}>Change Password</Button>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="" maxLength={40} required value={firstname} onChange={(e)=>{setFirstname(e.target.value)}}/>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="" maxLength={40} value={lastname} onChange={(e)=>{setLastname(e.target.value)}}/>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>Email</Form.Label>
                        <div className='custominput'>
                            <Form.Control type="email" placeholder="" maxLength={80} required value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            <Form.Text className="text-muted">Email will be used to recover the account in case you forgot the password. Please use an accurate email address.</Form.Text>
                        </div>
                    </Form.Group>
                    <Form.Group className="entry">
                        <Form.Label>Role</Form.Label>
                        <Form.Control type="text" placeholder=""  value={props.auth.role} disabled/>
                    </Form.Group>

                    {props.auth.role==="Doctor"?
                    <div>
                        <Form.Group className="entry">
                            <Form.Label>Doctor's Registration Number</Form.Label>
                            <div className='custominput'>
                                <Form.Control type="text" placeholder="" maxLength={6} value={doctorregno} required disabled={props.auth.doctorregno.trim()===""?false:true} onChange={(e)=>{setDoctorregno(e.target.value)}}/>
                                {props.auth.doctorregno.trim()===""?<Form.Text className="text-muted">Please enter the absolutly accurate registration number. Once entered, it is impossible to change.</Form.Text>:null}
                            </div>
                        </Form.Group>
                        <Form.Group className="entry">
                            <Form.Label>Doctor's Name on Seal</Form.Label>
                            <Form.Control type="text" placeholder="" maxLength={100} value={doctorname} required onChange={(e)=>{setDoctorname(e.target.value)}}/>
                        </Form.Group>
                        <Form.Group className="entry">
                            <Form.Label>Doctor's Qualifications on Seal</Form.Label>
                            <Form.Control size="sm" as="textarea" rows={4} type="text" maxLength={300} placeholder="" value={doctorseal} required onChange={(e)=>{setDoctorseal(e.target.value)}}/>
                        </Form.Group>
                    </div>
                    :null}

                    <div className='mt-4'>
                        <Button variant="primary" type="submit">Update User Details</Button>
                    </div> 
                </Form>

                <NavLink exact to={`/dashboard/`} className="mt-2 d-block">
                    Back To Patient Management
                </NavLink>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {updateUser})(OneUser);