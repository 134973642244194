import { SET_AUTH, SET_AUTH_LOGOUT } from "./types";

const initState = {
  checked: false,
  auth: false,
  firstname: "",
  lastname: "",
  role: "",
  id: "",
  username: '',
  email: '',
  doctorregno: '',
  doctorname: '',
  doctorseal: [],
  permission: "fffff",

  clinicname: "",
  clinicaddress: "",
  clinicphone: "",
  clinicphone2: "",
  clinicemail: "",
  clinicweb: "",
  cliniclogo: "",

  clinicprintformat: "hbf",

  error: "",
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        ...action.payload
      };
    case SET_AUTH_LOGOUT:
      return {
        ...initState,
        ...action.payload
      };
    default:
      return state;
  }
}
