import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { postPatient, searchPatient, selectPatient, openAccordion, setSelectedPatientHistory } from '../../actions';
import DashboardPatientHistory from '../dashboardPatientHistory/DashboardPatientHistory';

import "./DashboardPatientAdmissionSelection.css";
import lock from '../../assets/icons/lock.svg';
import up from '../../assets/icons/CaretUp.svg';
import down from '../../assets/icons/CaretDown.svg';
import edit from '../../assets/icons/Edit.svg';
import Extend from '../../assets/icons/Extend.svg';
import replay from "../../assets/icons/Replay.svg";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

function DashboardPatientAdmissionSelection(props) {
    const [mode, setMode] = useState("Select");
    const [title, setTitle] = useState("Mr");
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [phone, setPhone] = useState("");

    const [searchloading, setSearchLoading] = useState(false);
    const [searchname, setSearchName] = useState("");
    const [searchphone, setSearchPhone] = useState("");

    useEffect(()=>{
        setSearchLoading(true);
        const delayDebounceFn = setTimeout(() => {
            props.searchPatient({name: searchname, phone: searchphone});
            setSearchLoading(false);
        }, 1200);
        return ()=> clearTimeout(delayDebounceFn);
    }, [searchname, searchphone]);

    const addPatientHandler = ()=>{
        props.postPatient({
            title: title,
            name: name,
            age: age,
            phone: phone,
        });
        // clear data
        setTitle("Mr"); setName(""); setAge(""); setPhone("");
    }

    const listresult = ()=>{
        if (props.patient.search_results.length!==0){
            const content = props.patient.search_results.map((item, index) => <tr key={index}>
                <td><div className='text-primary button' onClick={()=>{props.selectPatient(index);}}>Select</div></td>
                <td className='t-name'>{item[1]}. {item[2]}</td>
                <td className='t-age'>{new Date().getFullYear() - parseInt(item[3])}yrs</td>
                <td className='d-none d-md-table-cell t-phone'>{item[4]}</td>
                <td className='d-none d-md-table-cell'>{item[5]}</td>
            </tr>)
            return <Table bordered hover responsive>
                <thead>
                    <tr>
                        <th></th><th>Name</th><th>Age</th><th className='d-none d-md-table-cell'>Phone</th><th className='d-none d-md-table-cell'>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {content}
                </tbody>
                <tfoot></tfoot>
            </Table>
        } else {
            return <div className='text-center'>No match found</div>
        }
    }

    return (
        <div className='dashboard-admission'>

            <DashboardPatientHistory/>

            <div className='mt-4' >
                <h2 className='h4 bg-light py-2 px-2 mb-0 d-flex justify-content-between according-head' onClick={props.openAccordion}>
                    <div className='d-flex'><span>Patient Selection or Admission</span></div>
                    <div>
                        {props.prescription.isFreeze?
                        <img src={lock} height={30} width={30} alt="lock icon"/>
                        :(
                            !props.patient.open ? (<img src={down} height={30} width={30} alt="down"/>):(<img src={up} height={30} width={30} alt="up"/>)
                        )}
                    </div>
                </h2>
                { props.patient.selected ? (
                    <div className='text-primary px-2 border d-flex justify-content-between'>
                        <div>
                        Patient: <span>{props.patient.title}</span>. <span>{props.patient.name}</span> | <span>{props.patient.age}yrs</span> | <span>
                            {props.patient.phone}</span> <span className='button'>
                                <NavLink exact to={`/dashboard/patient/${props.patient.id}`} className="nav-link inline">
                                    <img src={edit} height={20} width={20} alt="lock icon" style={{marginTop: "-3px"}} title="Edit Patient"/>
                                </NavLink>
                                
                            </span>
                        </div>
                        <div>
                            <Button variant="link" style={{margin: 0, padding: 0}} onClick={()=>{props.setSelectedPatientHistory(props.patient.id)}}>
                                <img src={replay} height={26} width={26} alt="lock icon" style={{marginTop: "-3px"}} title="Previous Prescriptions"/>
                            </Button>
                        </div>
                    </div>
                ): <div className='text-secondary px-2 border'>Please select a patient.</div>}
            </div>

            {props.auth.role=="Assistant"||props.patient.open?(
            <div className='border border-top-0 py-2 '>
                <div className='d-flex justify-content-center align-items-center pt-2'>
                    <Button variant={mode==="Select"?"secondary":"outline-secondary"} onClick={()=>setMode("Select")}>Select</Button>
                    <div className='px-4'><img src={Extend} alt="switch" width={30}/></div>
                    <Button variant={mode==="Admit"?"secondary":"outline-secondary"} onClick={()=>setMode("Admit")}>Admit</Button>
                </div>
                <hr/>

                {mode==="Admit"?(
                    <div className='mt-2 px-2'>
                        <Form onSubmit={(e)=>{e.preventDefault(); addPatientHandler(); }} validated={false}>
                            <div className="d-md-flex flex-wrap justify-content-md-center">
                                <Form.Group className="mb-2 me-2" controlId="admittitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Select aria-label="Select patient title" value={title} onChange={(e)=>{setTitle(e.target.value)}}>
                                        <option value="Mr">Mr</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Dr">Dr</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-2 me-2" controlId="admitname">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Patient name" maxLength={40} value={name} onChange={(e)=>{setName(e.target.value)}}/>
                                </Form.Group> 

                                <Form.Group className="mb-2 me-2" controlId="admitage">
                                    <Form.Label>Age</Form.Label>
                                    <Form.Control required type="number" placeholder="Age" min="0" max="130" value={age} onChange={(e)=>{setAge(e.target.value)}}/>
                                    <Form.Text id="admitagehelp" muted></Form.Text>
                                </Form.Group> 

                                <Form.Group className="mb-2 me-2" controlId="admitphone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control required type="text" placeholder="Phone" pattern="[0-9]{10}" maxLength={10} value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                                    <Form.Control.Feedback type="invalid">Should contain 10 digits</Form.Control.Feedback>
                                </Form.Group> 
                            </div>

                            <Form.Group className="mb-3 text-center" controlId="admitname">
                                <Button variant="primary" type="submit">Add</Button>
                            </Form.Group>

                            { props.patient.error!==""? <Alert variant={"danger"}>{props.patient.error}</Alert>: null}
                        </Form>
                    </div>
                ):(
                    <div className='mt-2 px-2'>
                        <div>
                            <h3 className='h5 text-center'>Search by:</h3>
                            <div className='d-md-flex flex-wrap justify-content-md-center'>
                                <Form.Group className="me-3 mb-2 form-inline" controlId="searchphone">
                                    <Form.Label className='me-2'>Phone:</Form.Label>
                                    <Form.Control type="text" placeholder="" className='d-inline-block' pattern="[0-9]{10}" maxLength={10} value={searchphone} onChange={(e)=>{setSearchPhone(e.target.value)}}/>
                                </Form.Group>
                                <Form.Group className="me-3  form-inline" controlId="searchname">
                                    <Form.Label className='me-2'>Name:</Form.Label>
                                    <Form.Control type="text" placeholder="" className='d-inline-block' maxLength={40} value={searchname} onChange={(e)=>{setSearchName(e.target.value)}}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div>
                            <h3 className='h5 text-center mt-3'>Results</h3>
                            <div className="results">
                                {searchloading?(
                                    <div className='text-center'>
                                        <Spinner animation="border" role="status" className='mt-2'>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ):(
                                    <div className='text-center'>
                                        <div className='d-inline-block text-start'>
                                            {listresult()}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )}

            </div>
            ):null}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patient: state.patient,
        prescription: state.prescription,
        auth: state.auth,
    }
}

export default connect(mapStateToProps, {postPatient, searchPatient, selectPatient, openAccordion, setSelectedPatientHistory})(DashboardPatientAdmissionSelection);
