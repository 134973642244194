import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { DateRangePicker } from 'react-date-range';
import { fetchFinanceNoRedux } from '../../actions';
import "../../assets/css/react-date-range/styles.css";
import "../../assets/css/react-date-range/default.css";
import "./DashboardAnalytics.css";



function DashboardAnalytics(props) {
    const [range, setRange] = useState({
        selection1: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection1'
        },
    });
    const [data, setData] = useState(null);

    const onApply = async ()=>{
        const data = {
            startYear: range.selection1.startDate.getFullYear(),
            startMonth: range.selection1.startDate.getMonth()+1,
            startDate: range.selection1.startDate.getDate(),
            endYear: range.selection1.endDate.getFullYear(),
            endMonth: range.selection1.endDate.getMonth()+1,
            endDate: range.selection1.endDate.getDate(),
        }
        const result = await fetchFinanceNoRedux(data);
        setData(result);
    }

    if (props.auth.permission[5]!="t"){
        return <Redirect to={{ 
            pathname: "/dashboard/"
        }}/>
    } else {
        return (
            <div className='analytics'>
                <h1 className='mt-5 h3 text-center text-uppercase'>Analytics</h1>
                <div>
                    <div className='text-center'>
                        <div className='d-inline-block'>
                            <div className='d-inline-block'>
                                <DateRangePicker
                                    onChange={item => setRange({ ...range, ...item })}
                                    ranges={[range.selection1]}
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary my-2" onClick={onApply}>Generate a Report</button>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    {data!==null?
                    <div className='report mb-5'>
                        <div className='general mt-5'>
                            <h2 className='h4'>General</h2>
                            <div className="entry">
                                <div>Reporting period</div><div>{data.daterange}</div>
                            </div>
                            <div className="entry">
                                <div>Number of prescription issued</div><div>{data.numberofprescriptions}</div>
                            </div>
                        </div>
                        <div className='finance mt-5'>
                            <h2 className='h4'>Finance</h2>
                            <div className="entry">
                                <div>Revenue</div><div>{data.revenue}</div>
                            </div>
                            <div className="entry level1">
                                <div>Doctor charges</div><div>{data.doctorcharge}</div>
                            </div>
                            <div className="entry level1">
                                <div>Selling drugs</div><div>{data.drugsellings}</div>
                            </div>
                            <div className="entry level1">
                                <div>Perform procedures</div><div>{data.procedurecharge}</div>
                            </div>

                            <div className="entry">
                                <div>Expenditures</div><div>{data.totalcost}</div>
                            </div>
                            <div className="entry level1">
                                <div>For drugs</div><div>{data.drugcost}</div>
                            </div>
                            <div className="entry level1">
                                <div>For procedures</div><div>{data.procedurecost}</div>
                            </div>

                            <div className="entry">
                                <div>Profit</div><div>{(data.revenue-data.totalcost).toFixed(2)}</div>
                            </div>
                            <div className="entry">
                                <div>Doctor's earnings</div><div>{data.doctorcharge}</div>
                            </div>
                        </div>
                    </div>
                    :null}

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(DashboardAnalytics);
