import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { updateClinicPrint } from '../../actions';

function PrintSettings(props) {
    const [printformat, setPrintformat] = useState("");

    useEffect(()=>{
        setPrintformat(props.auth.clinicprintformat);
    }, [props.auth]);

    const handleUpdateClinicPrint = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            // submit changes
            props.updateClinicPrint({
                printformat: printformat,
            });
        }
    }

    return (
        <div className='border rounded p-4 mb-5'>
            <h2 className='h5 pb-2 border-bottom mb-0'>Print Settings</h2>

            <Form onSubmit={handleUpdateClinicPrint}>
                <Form.Group className="entry">
                    <Form.Label>Printing Format</Form.Label>
                    <div className='custominput'>
                        <Form.Select aria-label="select print format" value={printformat} onChange={(e)=>{setPrintformat(e.target.value)}}>
                            <option value="hbf">Header-Body-Footer</option>
                            <option value="nbf">Body-Footer</option>
                            <option value="hbn">Header-Body</option>
                            <option value="nbn">Body</option>
                        </Form.Select>
                    </div>
                </Form.Group>
                
                <div className='mt-4'>
                    <Button variant="primary" type="submit">Update Print Settings</Button>
                </div>
            </Form>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {updateClinicPrint})(PrintSettings);