import { SET_PRESCRIPTION_HISTORY } from "./types";
import axios from "axios";
// import { setLoading, showToast } from "../../App";

export function fetchPrescriptions(){
    return async function(dispatch) {
        const payload = await axios.get("/backend/prescription/fetch_prescriptions/")
        .then((res)=>{
            return {isloading: false, pending: res.data.pendingObjList, other: res.data.recentObjList};
        })
        .catch(()=>{
            return {isloading: true};
        });

        dispatch({
            type: SET_PRESCRIPTION_HISTORY,
            payload: payload,
        });
    }
}

export function fetchPatientPrescriptions(id){
    return async function(dispatch) {
        const payload = await axios.get("/backend/prescription/fetch_patient_prescriptions/"+id)
        .then((res)=>{
            return {patientHistory: res.data.prescriptions};
        })
        .catch(()=>{
            return {patientHistory: []};
        });

        dispatch({
            type: SET_PRESCRIPTION_HISTORY,
            payload: payload,
        });
    }
}

export function setSelectedPatientHistory(id){
    return async function(dispatch, getState) {
        dispatch({
            type: SET_PRESCRIPTION_HISTORY,
            payload: {patientHistorySelected: id, patientHistoryVisible: true},
        });
    }
}

export function setPrescriptionHistory(payload){
    return async function(dispatch) {
        dispatch({
            type: SET_PRESCRIPTION_HISTORY,
            payload: payload,
        });
    }
}
