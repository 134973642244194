import { SET_PATIENT, RESET_PATIENT } from "./types";

const initState = {
    selected: false,
    id: "",
    title: "",
    name: "",
    age: "",
    birthyear: "",
    phone: "",
    date_created: "",

    search_results: [],
    results_loading: false,

    open: true,
    error: "",
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_PATIENT:
      return {
        ...state,
        ...action.payload
      };
    case RESET_PATIENT:
      return {
        ...state,
        ...initState
      };
    default:
      return state;
  }
}
