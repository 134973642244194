import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { verify } from '../../actions';

function PrivateRoute({auth, children, ...rest}) {
    useEffect(()=>{
        if(!auth.checked){rest.verify();}
    }, []);

    if (!auth.checked){
        return <div className='comp_loader'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    } else {
        return <Route 
                {...rest}
                render={({location})=>{
                    return auth.auth ? 
                    children : 
                    <Redirect to={{ 
                        pathname: "/login", state: {from: location}
                    }}/>
                }}
            >
        </Route>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps, {verify})(PrivateRoute);
