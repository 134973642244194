import React, { useState } from 'react';
import { connect } from 'react-redux';
import "./DashboardAddDrug.css";
import { addDrug as addDrugBackend, setInventory } from '../../actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

function DashboardAddDrug(props) {
    const [name, setName] = useState("");
    const [otherNames, setOtherNames] = useState("");
    const [nature, setNature] = useState("pill");
    const [unit, setUnit] = useState("mg");
    const [dose, setDose] = useState("");

    const [doseError, setDoseError] = useState("");
    const [variantError, setVariantError] = useState("");
    const [formError, setformError] = useState("");

    const [variants, setVariants] = useState([]);
    const [variant, setVariant] = useState({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
    const [selectedVariantIndex, setSelectedVariantIndex] = useState("");

    const addVariant = ()=>{
        const dose_string = dose.trim();
        const placeholder_variant = { dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" }
        
        // check empty input
        if (dose_string===""){ setDoseError("Dose should be a non-empty value."); return; }
        // check duplicate dose
        for (const v in variants){ if (variants[v].dose===dose_string) { setDoseError("Duplicate dose is unacceptable."); return;} }

        const newarray = variants.slice();
        newarray.push({...placeholder_variant, dose: dose_string});
        setVariants(newarray);
        setDoseError(""); setDose(""); 
        setSelectedVariantIndex((newarray.length-1) + ""); setVariant({...placeholder_variant, dose: dose_string});
        setVariantError("");
    }

    const saveVariant = ()=>{
        if (!validateVariant(variant)){ return setVariantError("Fields should be non-empty positive numbers."); }
        const newarray = variants.slice();
        newarray[selectedVariantIndex] = {...newarray[selectedVariantIndex], ...variant};
        setVariants(newarray);
        setVariant({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
        setSelectedVariantIndex("");
    }

    const removeVariant = ()=>{
        const newarray = variants.slice();
        newarray.splice(selectedVariantIndex, 1);
        setVariants(newarray);
        setVariant({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
        setSelectedVariantIndex("");
    }

    const cancelVariant = ()=>{
        setVariant({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
        setSelectedVariantIndex("");
    }

    const validateVariant = (vari)=>{
        if (vari.stock==="" || vari.warning==="" || vari.critical==="" || vari.sellPrice==="" || vari.buyPrice==="") { return false; }
        if (vari.stock<0 || vari.warning<0 || vari.critical<0 || vari.sellPrice<0 || vari.buyPrice<0) { return false; }
        return true;
    }

    const validateForm = ()=>{
        const errors = [];
        if (name===""){ errors.push("Please enter a drug name"); }
        if (variants.length===0){ errors.push("Please enter at least one variant"); }
        for (const i in variants){
            if (!validateVariant(variants[i])){
                errors.push("Missing infomation in variant "+variants[i].dose+" "+unit);
            }
        }
        if (errors.length===0){ return true; } else { return <ul>{errors.map((x, index)=><li key={index}>{x}</li>)}</ul>; }
    }

    const addDrug = ()=>{
        const validateform = validateForm();
        if (validateform!==true){ setformError(validateform); return; }
        setformError("");
        props.addDrugBackend({
            name: name.trim(),
            otherNames: otherNames.trim(),
            nature: nature,
            unit: unit,
            variants: variants,
        });
        setVariant({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
        setSelectedVariantIndex("");
        setVariants([]);
        setName(""); setOtherNames(""); setNature("pill"); setUnit("mg"); setDose(""); setDoseError(""); setVariantError(""); setformError("");
    }

    const cancelAdd = ()=>{
        props.setInventory({showAddDrug: false});
        setNature("pill");
        setVariant({ dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" });
        setSelectedVariantIndex("");
        setVariants([]);
        setName(""); setOtherNames(""); setUnit("mg"); setDose(""); setDoseError(""); setVariantError(""); setformError("");
    }

    const selectVariantToEdit = (index)=>{
        setSelectedVariantIndex(index); setDoseError("");
        setVariant(variants[index]);
        setVariantError("");
    }

    const displayVariants = ()=>{
        var component = [];
        for (const i in variants){
            if (i===selectedVariantIndex){
                component.push(<Badge bg="primary" key={i} onClick={()=>selectVariantToEdit(i)}>{variants[i].dose} {unit}</Badge>)
            } else {
                if (!validateVariant(variants[i])){
                    component.push(<Badge bg="warning" key={i} onClick={()=>selectVariantToEdit(i)}><i className="fa-solid fa-x"></i> {variants[i].dose} {unit}</Badge>)
                }else{
                    component.push(<Badge bg="success" key={i} onClick={()=>selectVariantToEdit(i)}><i className="fa-solid fa-check"></i> {variants[i].dose} {unit}</Badge>)
                }
                
            }
        }
        return <div>{component}</div>;
    }

    const handleNature = (value)=>{
        setNature(value);
        if (value=="pill"){
            const placeholder_variant = { dose: "", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" }
            setUnit("mg");
            setVariants([]);
            setDoseError(""); setDose(""); 
            setSelectedVariantIndex(""); setVariant({...placeholder_variant});
            setVariantError("");
        }else{
            // single fixed variant
            const placeholder_variant = { dose: "Stock", stock: "", warning: "", critical: "", sellPrice: "", buyPrice: "" }
            const newarray = [{...placeholder_variant}];
            setVariants(newarray);
            setUnit(""); 
            setDoseError(""); setDose("");
            setSelectedVariantIndex("0"); setVariant({...placeholder_variant});
            setVariantError("");
        }
    }

    return (
        <div>
            <Button variant="primary" onClick={()=>props.setInventory({showAddDrug: true})}>Add a Drug</Button>
        
            <Modal show={props.inventory.showAddDrug} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title>
                        Add a Drug
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Group className="mb-3" controlId="drugname">
                            <Form.Label>Drug name</Form.Label>
                            <Form.Control type="text" placeholder="Drug name" maxLength={100} required value={name} onChange={(e)=>{setName(e.target.value)}}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="othernames">
                            <Form.Label>Other names if available</Form.Label>
                            <Form.Control type="text" value={otherNames} onChange={(e)=>{setOtherNames(e.target.value)}}/>
                            <Form.Text className="text-muted">Use comma separated formatting to specify more than one name.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="nature">
                            <Form.Label>Nature</Form.Label>
                            <Form.Select aria-label="select unit to measure dose" value={nature} onChange={(e)=>{handleNature(e.target.value)}}>
                                <option value="pill">Pill</option>
                                <option value="drop">Drop</option>
                                <option value="syrup">Syrup</option>
                                <option value="gel">Gel</option>
                            </Form.Select>
                        </Form.Group>
                        {nature=="pill"?
                        <Form.Group className="mb-2" controlId="unit">
                            <Form.Label>Unit use to measure the dose</Form.Label>
                            <Form.Select aria-label="select unit to measure dose" value={unit} onChange={(e)=>{setUnit(e.target.value)}}>
                                <option value="mg">mg</option>
                                <option value="g">g</option>
                                <option value="µg">µg</option>
                                <option value="IU">IU</option>
                                <option value="U">U</option>
                            </Form.Select>
                        </Form.Group>
                        :null}
                    
                        {nature=="pill"?
                        <div className='inline-block mt-4'>
                            <div className='h5 pe-3 d-inline-block align-top'>Dose Variants </div>
                            <div className="d-block align-top">
                                <Form.Group className="pe-3 d-inline-block" controlId="dose" style={{maxWidth: "150px"}}>
                                    <Form.Control type="number" placeholder={"Dose (" +unit+")"} min="0" value={dose} onChange={(e)=>{setDose(e.target.value)}}/>
                                </Form.Group>
                                <Button variant="secondary" size="sm" className='align-baseline' onClick={addVariant}>Add Variant</Button>
                                {doseError!==""?<div className='form-text text-danger'>{doseError}</div>:null}
                            </div>
                        </div>
                        :null}
                        
                        { variants.length!==0 ? <div className='add-drug py-2'>
                            {displayVariants()}
                        </div>
                        :null}
                        

                        { selectedVariantIndex !== "" ? (
                            <div className='border p-3'>
                                { nature==="pill"?
                                <div className='h6 mb-4'>Variant - {variants[selectedVariantIndex].dose} {unit}</div>
                                :null}
                                <div className="d-md-flex flex-wrap">
                                    <Form.Group className="mb-3 pe-3" controlId="stock">
                                        <Form.Label>Available stock</Form.Label>
                                        <Form.Control type="number" placeholder="Available stock" min="0" required 
                                        value={variant.stock} onChange={(e)=>{setVariant({...variant, stock: e.target.value})}}/>
                                    </Form.Group>
        
                                    <Form.Group className="mb-3 pe-3" controlId="warning">
                                        <Form.Label>Warning limit</Form.Label>
                                        <Form.Control type="number" placeholder="Warning limit" min="0" required
                                        value={variant.warning} onChange={(e)=>{setVariant({...variant, warning: e.target.value})}}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 pe-3" controlId="critical">
                                        <Form.Label>Critical limit</Form.Label>
                                        <Form.Control type="number" placeholder="Critical limit" min="0" required
                                        value={variant.critical} onChange={(e)=>{setVariant({...variant, critical: e.target.value})}}/>
                                    </Form.Group>
    
                                    <Form.Group className="mb-3 pe-3" controlId="sellprice">
                                        <Form.Label>Selling price (Rs)</Form.Label>
                                        <Form.Control type="number" placeholder="Selling price" min="0" required
                                        value={variant.sellPrice} onChange={(e)=>{setVariant({...variant, sellPrice: e.target.value})}}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 pe-3" controlId="buyprice">
                                        <Form.Label>Buying price (Rs)</Form.Label>
                                        <Form.Control type="number" placeholder="Buying price" min="0" required
                                        value={variant.buyPrice} onChange={(e)=>{setVariant({...variant, buyPrice: e.target.value})}}/>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Button variant="secondary" size="sm" onClick={cancelVariant} className='me-2'>Cancel</Button>
                                    {nature==="pill"?
                                    <Button variant="danger" size="sm" onClick={removeVariant} className='me-2'>Remove</Button>
                                    :null}
                                    <Button variant="primary" size="sm" onClick={saveVariant} className='px-3'>Save</Button>
                                </div>
                                {variantError!==""?<div className='form-text text-danger'>{variantError}</div>:null}
                            </div>
                        ):null}

                        {(formError!=="")?<div className='form-text text-danger mt-3 border border-danger pt-3'>
                            {formError}
                        </div>:null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelAdd}>Cancel</Button>
                    <Button variant="primary" onClick={addDrug}>Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        inventory: state.inventory
    }
}

export default connect(mapStateToProps, {addDrugBackend, setInventory})(DashboardAddDrug);
