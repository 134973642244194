import { SET_INVENTORY } from "./types";

const initState = {
  showAddDrug: false,
  showEditDrug: false,
  showRefill: false,

  drugList: [],

  editVariantId: "",
  variant: null,

  refillVariantId: "",
  refillList: [],
  refillVariant: null,
  
  errors: "",
  info: "",
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_INVENTORY:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
