import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { DashboardAddDrug, DashboardListDrugs, DashboardEditDrug, DashboardRefill } from '../../containers';

function DashboardInventoryManagement(props) {

    if (props.auth.permission[2]!="t"){
        return <Redirect to={{ 
            pathname: "/dashboard/"
        }}/>
    } else {
        return (
            <div>
                <h1 className='mt-5 h3 text-center text-uppercase'>Inventory Management</h1>

                <div className='mt-4'>

                    <DashboardAddDrug/>

                    <DashboardEditDrug/>

                    <DashboardRefill/>

                    <DashboardListDrugs/>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(DashboardInventoryManagement);
