import { SET_ANALYTICS } from "./types";
import axios from "axios";
import { setLoading, showToast } from "../../App";

// export function setLoading(comp_loading, page_loading=false){
//     return async function(dispatch) { 
//         // const { common } = getState();
//         const payload = { comp_loading: comp_loading, page_loading: page_loading };
//         dispatch({
//             type: SET_ANALYTICS,
//             payload: payload,
//         });
//     }
// }

export async function fetchFinanceNoRedux(date_range){
    setLoading(true);
    const payload = await axios.post("/backend/analytics/fetch_finance/", {...date_range})
    .then((res)=>{
        return res.data
    })
    .catch(()=>{
        return {}
    });
    setLoading(false);
    return payload;
}

