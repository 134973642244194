import { SET_PATIENT, RESET_PATIENT } from "./types";
import axios from "axios";
import { setLoading, showToast, store } from "../../App";
import { openPrescription } from "../index";

export function postPatient(data){
    return async function(dispatch) {
        setLoading(true);

        data["birthyear"] = new Date().getFullYear() - parseInt(data["age"]);

        const payload = await axios.post("/backend/patient/add/", data)
        .then((res)=>{
            showToast({ show_toast: true, toast_message: "Patient added and selected." });
            return {error: "", selected: true, open: false, ...res.data};
        })
        .catch(()=>{
            return {error: "Something went wrong!", selected: false, open: true};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_PATIENT,
            payload: { ...data, ...payload },
        });
        store.dispatch(openPrescription(true));
    }
}

export function searchPatient(data){
    return async function(dispatch) {
        if (data["name"].trim()!=="" || data["phone"].trim().length===10){ } else { return; }
        const payload = await axios.post("/backend/patient/search/", data)
        .then((res)=>{
            return {search_results: res.data.data}
        })
        .catch(()=>{
            return {search_results: {}}
        });

        dispatch({
            type: SET_PATIENT,
            payload: payload,
        });
    }
}

export function updatePatient(data){
    return async function(dispatch) {
        setLoading(true);

        data["birthyear"] = new Date().getFullYear() - parseInt(data["age"]);

        const payload = await axios.post("/backend/patient/update/", data)
        .then((res)=>{
            showToast({ show_toast: true, toast_message: "Patient details updated." });
            return {...res.data};
        })
        .catch(()=>{
            showToast({show_toast: true, toast_message: "Patient details updating failed.", show_error: true});
            return {};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_PATIENT,
            payload: { ...payload },
        });
    }
}

export function selectPatient(index){
    return async function(dispatch, getState) {
        const { patient } = getState();
        const selected = patient.search_results[index];

        const payload = {
            selected: true, open: false,
            id: selected[0],
            title: selected[1],
            name: selected[2], 
            birthyear: selected[3],
            phone: selected[4],
            date_created: selected[5],
            age: new Date().getFullYear() - parseInt(selected[3]),
        };

        showToast({ show_toast: true, toast_message: "Patient selected." });

        dispatch({
            type: SET_PATIENT,
            payload: payload,
        });

        store.dispatch(openPrescription(true));
    }
}

export function openAccordion(){
    return async function(dispatch, getState) {
        const { patient, prescription } = getState();
        const payload = {};
        if (patient.selected) {
            payload["open"] = !patient.open;
        } else {
            payload["open"] = true;
        }
        if (prescription.isFreeze){ payload["open"] = false; }
        dispatch({
            type: SET_PATIENT,
            payload: payload,
        });
    }
}

export function resetPatient(){
    return async function(dispatch) {
        dispatch({
            type: RESET_PATIENT,
            payload: {},
        });
    }
}
