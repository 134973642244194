import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDrugList, setEditVariantId, setRefillVariantId } from '../../actions';
import "./DashboardListDrugs.css";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { rankItem, } from '@tanstack/match-sorter-utils'
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
  
    // Store the itemRank info
    addMeta({
      itemRank,
    })
  
    // Return if the item should be filtered in/out
    return itemRank.passed
}

var debounce_timeout = null;
const columnHelper = createColumnHelper();

function DashboardListDrugs(props) {
    const [data, setData] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [inputGlobalFilter, setInputGlobalFilter] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [columnVisibility, setColumnVisibility] = React.useState({othernames: false,})

    const columns = [
        // columnHelper.accessor('drug', {
        //     id: "drug",
        //     header: "Drug",
        // }),
        columnHelper.accessor((row)=>`${row.drug} ${row.nature!=="pill"?"("+row.nature.slice(0, 2)+")":""}`, {
            id: "drug",
            header: "Drug",
        }),
        columnHelper.accessor('othernames', {
            id: "othernames",
            header: "Other Names",
        }),
        columnHelper.accessor((row)=>`${row.dose}${row.unit}`, {
            id: "dose",
            header: "Dose",
        }),
        columnHelper.accessor("sellingPrice", {
            id: 'sellingPrice',
            header: "Sell Price (Rs)",
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('limCritical', {
            id: "limCritical",
            header: "CL",
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('limWarning', {
            id: "limWarning",
            header: "WL",
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('stock', {
            id: "stock",
            header: "Stock",
            cell: (i)=>{ 
                const warning = i.row.getValue("limWarning");
                const critical = i.row.getValue("limCritical");
                const stock = i.getValue();
                if (stock>warning){ return <Badge bg="success">{stock}</Badge>}
                else if (stock>critical){ return <Badge bg="warning" className='text-dark'>{stock}</Badge>}
                else { return <Badge bg="danger">{stock}</Badge> }
            },
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('updated', {
            id: "updated",
            header: "Last Updated",
            enableGlobalFilter: false,
        }),
        columnHelper.accessor('drugVariantId', {
            id: "drugVariantId",
            header: "Actions",
            cell: (i)=>{ 
                const variantId = i.row.getValue("drugVariantId");
                return <>
                    <Button variant="outline-primary" size="sm" className='me-2' onClick={()=>{props.setEditVariantId(variantId)}}>Edit</Button>
                    <Button variant="outline-primary" size="sm" onClick={()=>{props.setRefillVariantId(variantId)}}>Add Stock</Button>
                </>
            },
            enableGlobalFilter: false,
        }),
    ]

    const enterSearchDeBounce = (value)=>{
        setInputGlobalFilter(value); setSearchLoading(true);
        try { clearTimeout(debounce_timeout); } catch {};
        debounce_timeout = setTimeout(()=>{
            setGlobalFilter(value); setSearchLoading(false);
        }, 1000);
    }

    const table = useReactTable({
        data,
        columns,
        state: { sorting, globalFilter, columnVisibility }, 
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        debugTable: false,
    });
    // https://tanstack.com/table/v8/docs/api/core/table

    useEffect(()=>{
        props.getDrugList();
    }, []); 

    useEffect(()=>{
        setData(props.inventory.drugList);
    }, [props.inventory.drugList]);

    return (
        <div className="listDrugs">
            <div style={{marginTop: "20px"}}>
                <Form.Group className="mb-3 d-inline-block" controlId="drugname">
                    <Form.Control className="d-inline-block" type="text" placeholder="Search" maxLength={100} required value={inputGlobalFilter} onChange={(e)=>{enterSearchDeBounce(e.target.value)}}/>
                    {searchLoading? <Spinner animation="border" role="status" style={{position: 'absolute', marginLeft: "5px", marginTop: "2px"}}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : null}
                </Form.Group>
            </div>
            <table className="table table-bordered table-hover">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                    <div
                                    {...{
                                        className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}
                                    >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    {{
                                        asc: ' 🔼',
                                        desc: ' 🔽',
                                    }[header.column.getIsSorted()] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        inventory: state.inventory,
    }
}

export default connect(mapStateToProps, {getDrugList, setEditVariantId, setRefillVariantId})(DashboardListDrugs);
