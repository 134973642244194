import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PrescriptionPreview from './PrescriptionPreview';
import up from '../../assets/icons/CaretUp.svg';
import down from '../../assets/icons/CaretDown.svg';
import './DashboardBill.css';
import Invoice from './Invoice';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { resetPatient, resetPrescription, showToast, nextPatient } from '../../actions';

function DashboardBill(props) {
    const [withInternal, setWithInternal] = useState(true);

    const displayStatus = ()=>{
        switch (props.prescription.status) {
            case "pending":
                return <Badge bg="warning" text="dark">Pending</Badge>
            case "spaid":
                return <Badge bg="success">Paid for Service</Badge>
            case "fpaid":
                return <Badge bg="success">Paid</Badge>
            case "removed":
                return <Badge bg="secondary">Discarded</Badge>
            default:
                return "";
        }
    }

    const nextPatient = ()=>{
        props.resetPatient();
        props.resetPrescription();
        props.nextPatient();
    }

    return (
        <div className='dashboard-bill border border-top-0'>
            <div className='mt-4' >
                <h2 className='h4 bg-light py-2 px-2 mb-0 d-flex justify-content-between according-head'>
                    <div className='d-flex'><span>Bill</span>
                        {props.prescription.isFreeze?
                        <span style={{paddingLeft: "10px", fontSize: "15px", lineHeight: "2"}}>Status: {displayStatus()}</span>
                        :null}
                    </div>
                    <div>
                        {!props.prescription.isFreeze ? (<img src={down} height={30} width={30} alt="down"/>):(<img src={up} height={30} width={30} alt="up"/>)}
                    </div>
                </h2>
            </div>

            {props.prescription.isFreeze?(
            <>
                <PrescriptionPreview prescription={props.prescription} noInternal={!withInternal}/>
                <div>
                    <Invoice/>
                    <div className='printing-actions'>
                        <Button variant="primary" onClick={()=>{window.print()}}>Print</Button>
                        <Form.Check
                            style={{display: 'inline-block'}}
                            type="checkbox"
                            label="With internal drugs"
                            checked={withInternal}
                            onChange={()=>{setWithInternal(!withInternal)}} 
                        />
                    </div>
                    <div className='printing-actions'>
                        <Button variant="success" onClick={nextPatient}>Next Patient</Button>
                    </div>
                </div>
            </>
            )
            :null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription
    }
}

export default connect(mapStateToProps, {resetPatient, resetPrescription, showToast, nextPatient})(DashboardBill);
