import { SET_PRESCRIPTION_HISTORY } from "./types";

const initState = {
  isloading: true,
  pending: [],
  other: [],

  patientHistoryVisible: false,
  patientHistory:[],
  patientHistorySelected: null,
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_PRESCRIPTION_HISTORY:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
