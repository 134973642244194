import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import ClinicSettings from './ClinicSettings';
import "./DashboardClinicSettings.css";
import PrintSettings from './PrintSettings';

function DashboardClinicSettings(props) {
    if (props.auth.permission[4]!="t"){
        return <Redirect to={{ 
            pathname: "/dashboard/"
        }}/>
    } else {
        return (
            <div className='settings'>
                <h1 className='mt-5 h3 text-center text-uppercase'>Settings</h1>
                <ClinicSettings/>
                <PrintSettings/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(DashboardClinicSettings);
