import React from 'react';
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./PublicNavbar.css";

function PublicNavbar() {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
                <Container>
                    <NavLink to="/" className="navbar-brand">MediCenter.LK</NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to="/plans" className="nav-link" activeClassName="active">Plans</NavLink>
                            <NavLink to="/register" className="nav-link" activeClassName="active">Register</NavLink>
                            <NavLink to="/login" className="nav-link" activeClassName="active">Login</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default PublicNavbar;
