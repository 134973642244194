import React from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import Toast from 'react-bootstrap/Toast';
import { showToast } from '../../actions';
import "./ToastM.css";

function ToastM(props) {
    return (
        <Toast bg={props.common.show_error?"danger":"success"} onClose={() => props.showToast({show_toast: false, exit: true})} 
        show={props.common.show_toast} delay={8000} autohide={props.common.autohide}>
            <Toast.Header>
                <strong className="me-auto">{props.common.show_error? "Error!":"Success"}</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>
                { parse(props.common.toast_message) }
            </Toast.Body>
        </Toast>
    )
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {showToast})(ToastM);