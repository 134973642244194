import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { fetchPrescription } from '../../actions';
import PrescriptionPreview from '../dashboardBill/PrescriptionPreview';
import Invoice from '../dashboardBill/Invoice';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import "./OnePrescription.css";
import { LoaderA } from '../../components';

function OnePrescription(props) {

    const [withInternal, setWithInternal] = useState(true);

    useEffect(()=>{
        props.fetchPrescription(props.match.params.id);
    }, [props.match.params.id]);

    const displayStatus = ()=>{
        switch (props.prescription?.status) {
            case "pending":
                return <Badge bg="warning" text="dark">Pending</Badge>
            case "spaid":
                return <Badge bg="success">Paid for Service</Badge>
            case "fpaid":
                return <Badge bg="success">Paid</Badge>
            case "removed":
                return <Badge bg="secondary">Discarded</Badge>
            default:
                return "";
        }
    }

    return (
        <div className='oneprescription'>
            {props.common.comp_loading?<LoaderA/>:null}
            <PrescriptionPreview prescription={props.prescription} noInternal={!withInternal}/>
            <div className='mt-5 h3 text-center text-uppercase'>Prescription - {displayStatus()}</div>
            <div className='content'>
                <div className='head row border-bottom'>
                    <div className='patien col-lg-6'>
                        <div className='h6'>
                            Patient Information
                        </div>
                        <div>
                            {props.prescription?.patienttitle} {props.prescription?.patientname} - {props.prescription?.patientage}y
                        </div>
                    </div>
                    <div className='doctor col-lg-6'>
                        <div className='h6'>
                            Doctor Information
                        </div>
                        <div>
                            {props.prescription?.doctorname} - Reg. No. {props.prescription?.doctorregno}
                        </div>
                        <div>
                            {props.prescription?.doctorseal.map((d, k)=><div key={k}>{d}</div>)}
                        </div>
                    </div>
                </div>
                <Invoice/>
                <div className='printing-actions'>
                    <Button variant="primary" onClick={()=>{window.print()}}>Print</Button>
                    <Form.Check
                        style={{display: 'inline-block'}}
                        type="checkbox"
                        label="With internal drugs"
                        checked={withInternal}
                        onChange={()=>{setWithInternal(!withInternal)}} 
                    />
                </div>
                <div className='printing-actions'>
                    {props.auth.role!=="Doctor"?
                    <NavLink exact to={`/dashboard/prescriptions/`}>
                        <Button variant='success'>Next Prescription</Button>
                    </NavLink>
                    :
                    <NavLink exact to={`/dashboard/`}>
                        <Button variant='success'>Next Patient</Button>
                    </NavLink>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription,
        auth: state.auth,
        common: state.common,
    }
}

export default connect(mapStateToProps, {fetchPrescription})(OnePrescription);