import React, { useState } from 'react';
import { connect } from 'react-redux';
import './Invoice.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProcedureLine from './ProcedureLine';
import { paid } from '../../actions';

function Invoice({prescription, paid, auth}) {
    const [dcharge, setDCharge] = useState("");
    const [procedures, setProcedures] = useState([]);

    const addProcedure = ()=>{
        const copyprocedures = [...procedures];
        copyprocedures.push({});
        setProcedures(copyprocedures);
    }

    const updateProcedures = (data)=>{
        const copyprocedures = [...procedures];
        copyprocedures[data.procedureId] = data;
        setProcedures(copyprocedures);
    }

    const procedureLines = ()=>{
        return procedures.map((data, i)=><ProcedureLine key={i} procedureId={i} onChange={updateProcedures}/>);
    }

    const displayProcedureLines = ()=>{
        return prescription.procedures.map((data, i)=><div key={i} className='price-line procedures display'>
            <div>{data[0]}</div>
            <div className='amount'>{data[1].toFixed(2)}</div>
        </div>);
    }

    const displayDrugLines = ()=>{
        return prescription.drugs.map((d, i)=><div key={i} className='price-line procedures drugs'>
            <div>
                {d[1]} | Count: {d[6]}
            </div>
            <div></div>
        </div>);
    }

    const calcTotal = ()=>{
        var tot = parseFloat(prescription.drugSellingPrice);
        tot += parseFloat(dcharge);
        for(const i in procedures){
            if (procedures[i].name===undefined || procedures[i].name.trim()==="" || procedures[i].charge===undefined){
                continue;
            } else {
                if (procedures[i].charge!==""){
                    tot += parseFloat(procedures[i].charge);
                }
            }
        }
        return tot.toFixed(2);
    }
    const calcServiceCharge = ()=>{
        return (calcTotal()-parseFloat(prescription.drugSellingPrice)).toFixed(2);
    }

    const handlePaid = ()=>{
        paid({
            total: calcTotal(),
            dcharge: dcharge,
            procedures: procedures,
            method: "fpaid",
        });
    }
    const handleSPaid = ()=>{
        paid({
            total: calcTotal(),
            dcharge: dcharge,
            procedures: procedures,
            method: "spaid",
        });
    }
    const handleDiscard = ()=>{
        paid({
            total: calcTotal(),
            dcharge: dcharge,
            procedures: procedures,
            method: "removed",
        });
    }

    return (
        <div className='invoice'>
            {prescription.status!="pending"?<div className='freeze'></div>:null}
            <div className='price-line'>
                <div>Charge for drugs</div>
                <div>{prescription.drugSellingPrice}</div>
            </div>
            {displayDrugLines()}
            <div className='price-line'>
                <div>Doctor's charge</div>
                <div className='amount'>
                    {!prescription.isComplete?
                    <Form.Control size="sm" type="number" min="0" placeholder="Doc Fee" value={dcharge} onChange={(e)=>{setDCharge(e.target.value.trim())}} />
                    :
                    <div>{prescription.doctorcharge}</div>
                    }
                </div>
            </div>

            <div className='price-line'>
                <div>Procedure charges</div>
                <div></div>
            </div>
            {!prescription.isComplete?
            procedureLines()
            :
            displayProcedureLines()
            }

            {!prescription.isComplete?
            <div className='price-line procedures'>
                <div><Button variant="secondary" size="sm" onClick={addProcedure}>+</Button></div>
                <div></div>
            </div>:null}
            
            {!prescription.isComplete?
            prescription.status=="spaid"?
            <div className='price-line subtotal'>
                <div>Total Service Charge</div>
                <div>LKR {calcServiceCharge()}</div>
            </div>
            :
            <div className='price-line subtotal'>
                <div>Subtotal</div>
                <div>LKR {calcTotal()}</div>
            </div>
            : 
            prescription.status=="spaid"?
            <div className='price-line subtotal'>
                <div>Total Service Charge</div>
                <div>LKR {prescription.totalcharge}</div>
            </div>
            :
            <div className='price-line subtotal'>
                <div>Subtotal</div>
                <div>LKR {prescription.totalcharge}</div>
            </div>
            }
            
            {prescription.isComplete?<div className='price-line'>
                <div>Prepared by {prescription.cashier}</div>
            </div>:null}

            {prescription.status=="pending"?
            <div className='actions'>
                <Button variant="primary" onClick={handlePaid} disabled={auth.permission[3]!=="t"}>Paid</Button>
                <Button variant="secondary" onClick={handleSPaid} disabled={auth.permission[3]!=="t"}>Paid For Service - {calcServiceCharge()}</Button>
                <Button variant="danger" onClick={handleDiscard} disabled={auth.permission[3]!=="t"}>Discard</Button>
            </div>:null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {paid})(Invoice);
