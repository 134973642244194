import React from 'react';
import Container from 'react-bootstrap/Container';

function Landing() {
    return (
        <Container>
            Landing page :)
        </Container>
    )
}

export default Landing;
