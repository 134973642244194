import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { updateClinic } from '../../actions';

function ClinicSettings(props) {
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [email, setEmail] = useState("");
    const [web, setWeb] = useState("");
    const [logoFile, setLogoFile] = useState(null);

    const [logo, setLogo] = useState("");
    const [error, setError] = useState("");

    useEffect(()=>{
        setName(props.auth.clinicname);
        setAddress(props.auth.clinicaddress);
        setPhone(props.auth.clinicphone);
        setPhone2(props.auth.clinicphone2);
        setEmail(props.auth.clinicemail);
        setWeb(props.auth.clinicweb);
        setLogo(props.auth.cliniclogo);
    }, [props.auth]);

    const handleUpdateClinicDetails = (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            // submit changes
            props.updateClinic({
                name: name,
                address: address,
                phone: phone,
                phone2: phone2,
                email: email,
                web: web,
                logo: logoFile,
            });
        }
    }

    const validateFile = (file)=>{
        if (file.size/(1024*1024) > 5){ return "Logo size must be smaller than 5MB."}
        if (!file.type.includes("image/")) { return "Invalid image format."}
        return true;
    }
    const handleImageUpload = (e)=>{
        if(e.target.files.length==0){ return; } 
        const file = e.target.files[0];
        const isValid = validateFile(file);
        if (isValid!=true){ e.target.value = ''; setError(isValid); setLogo(""); setLogoFile(null); return; }
        setLogo(URL.createObjectURL(file)); setError(""); setLogoFile(file);
    }

    return (
        <div className='border rounded p-4 form mb-5'>
            <h2 className='h5 pb-2 border-bottom mb-0'>Clinic Details</h2>

            <Form onSubmit={handleUpdateClinicDetails}>
                <Form.Group className="entry">
                    <Form.Label>Name of the medical center</Form.Label>
                    <Form.Control type="text" placeholder="" maxLength={50} required value={name} onChange={(e)=>{setName(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="entry">
                    <Form.Label>Address</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="" maxLength={300} required value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="entry">
                    <Form.Label>Phone - 1</Form.Label>
                    <Form.Control type="text" placeholder="" maxLength={20} required value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="entry">
                    <Form.Label>Phone - 2</Form.Label>
                    <Form.Control type="text" placeholder="" maxLength={20} value={phone2} onChange={(e)=>{setPhone2(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="entry">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" placeholder="" maxLength={50} value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                </Form.Group>
                <Form.Group className="entry">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="text" placeholder="" maxLength={50} value={web} onChange={(e)=>{setWeb(e.target.value)}}/>
                </Form.Group>
                
                <Form.Group className="entry">
                    <Form.Label>Logo</Form.Label>
                    <div className='custominput'>
                        <div className='logopreview'>
                            <img src={logo} alt="logo"/>
                        </div>
                        <Form.Control type="file" accept="image/png" onChange={handleImageUpload}/>
                        <Form.Text className="text-muted">Logo must be no larger than 5MB. Acceptable file formats are SVG and PNG.</Form.Text><br/>
                        {error!=""?
                        <Form.Text className="error text-danger">{error}</Form.Text>
                        :null}
                    </div>
                </Form.Group>
                <div className='mt-4'>
                    <Button variant="primary" type="submit">Update Clinic Details</Button>
                </div>
            </Form>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {updateClinic})(ClinicSettings);