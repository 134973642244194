import { SET_COMMON } from "./types";

export function setLoading(comp_loading, page_loading=false){
    return async function(dispatch) { 
        // const { common } = getState();
        const payload = { comp_loading: comp_loading, page_loading: page_loading };
        dispatch({
            type: SET_COMMON,
            payload: payload,
        });
    }
}

export function showToast(payload){
    return async function(dispatch) { 
        if (payload.exit){
            dispatch({
                type: SET_COMMON,
                payload: {...payload},
            });
        } else {
            dispatch({
                type: SET_COMMON,
                payload: {autohide: true, toast_message: "", show_error: false, ...payload},
            });
        }
    }
}

export function nextPatient(){
    return async function(dispatch, getState) { 
        const { common } = getState();
        dispatch({
            type: SET_COMMON,
            payload: {patientNo: common.patientNo+1},
        });
    }
}
