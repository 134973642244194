import { SET_AUTH, SET_AUTH_LOGOUT } from "./types";
import axios from "axios";
import { setLoading, showToast } from "../../App";


export function login(username, password){
    return async function(dispatch) {
        setLoading(true);

        // Get token
        const payload = await axios.post("/auth/token/", {username: username, password: password})
        .then((res)=>{ 
            localStorage.setItem("at", res.data.access);
            localStorage.setItem("rt", res.data.refresh);
            return { checked: true, auth: true }
        })
        .catch((res)=>{ 
            return {error: "Invalid username or password.", token: ""}
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function logout(){
    return async function(dispatch) {
        const payload = { checked: true, auth: false }
        localStorage.removeItem("at");
        localStorage.removeItem("rt");
        dispatch({
            type: SET_AUTH_LOGOUT,
            payload: payload,
        });
    }
}

export function refreshToken(){
    return async function(dispatch) {
        // Refresh token aynschonously
        await axios.post("/auth/token/refresh/", {refresh: localStorage.getItem("rt")})
        .then((res)=>{ 
            localStorage.setItem("at", res.data.access);
            return {}
        })
        .catch((res)=>{ 
            return {}
        });
    }
}

export function verify(){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/auth/token/verify/", {token: localStorage.getItem("at")})
        .then((res)=>{
            return {checked: true, auth: true};
        })
        .catch((res)=>{
            return {checked: true, auth: false};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function fetchUser(){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/auth/fetch_user/")
        .then((res)=>{
            setLoading(false);
            return res.data;
        })
        .catch((res)=>{
            console.error(res);
            setLoading(true);
        });

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function updateUser(data){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/auth/update_user/", data)
        .then((res)=>{
            if (res.data.error!=undefined){
                showToast({show_toast: true, toast_message: "Username already exists. Please try a different one.", show_error: true});
                return {...res.data};
            } else {
                showToast({ show_toast: true, toast_message: "User details updated." });
                return {...res.data, error: ""};
            }
        })
        .catch((res)=>{
            showToast({show_toast: true, toast_message: "User details updating failed.", show_error: true});
            console.error(res);
            return {error: ""};
        }).finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function updateClinic(data){
    return async function(dispatch) {
        setLoading(true);

        var formData = new FormData()
        formData.append("name", data["name"]);
        formData.append("address", data["address"]);
        formData.append("phone", data["phone"]);
        formData.append("phone2", data["phone2"]);
        formData.append("email", data["email"]);
        formData.append("web", data["web"]);
        formData.append("logo", data["logo"]);

        const payload = await axios.post("/backend/clinic/update_clinic/", formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res)=>{
            showToast({show_toast: true, toast_message: "Clinic details updated."});
            return res.data;
        })
        .catch((res)=>{
            console.error(res);
            showToast({show_toast: true, toast_message: "Clinic details updating failed.", show_error: true});
            return {};
        })
        .finally(()=>{
            setLoading(false);
        })

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function updateClinicPrint(data){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/backend/clinic/update_clinicprint/", data)
        .then((res)=>{
            showToast({show_toast: true, toast_message: "Print settings updated."});
            return res.data;
        })
        .catch((res)=>{
            console.error(res);
            showToast({show_toast: true, toast_message: "Print settings updating failed.", show_error: true});
            return {};
        })
        .finally(()=>{
            setLoading(false);
        })

        dispatch({
            type: SET_AUTH,
            payload: payload,
        });
    }
}

export function setAuth(data){
    return async function(dispatch) {
        dispatch({
            type: SET_AUTH,
            payload: data,
        });
    }
}

export async function changeUserPasswordNoRedux(data){
    setLoading(true);

    if (data["newPassword"]!==data["newPassword2"]){
        setLoading(false);
        showToast({show_toast: true, toast_message: "Passwords are not matching.", show_error: true});
        return {error: "Passwords are not matching."}
    } else if (data["newPassword"].length<8){
        setLoading(false);
        showToast({show_toast: true, toast_message: "New password is too small.", show_error: true});
        return {error: "New password is too small."}
    }

    const payload = await axios.post("/auth/change_password/", data)
    .then((res)=>{
        if (res.data.error!=undefined){
            showToast({show_toast: true, toast_message: res.data.error, show_error: true});
            return {...res.data};
        } else {
            showToast({ show_toast: true, toast_message: res.data.info });
            return {...res.data};
        }
    })
    .catch((res)=>{
        showToast({show_toast: true, toast_message: "Internal server error. Please try again later.", show_error: true});
        console.error(res);
        return {error: "Internal server error. Please try again later."};
    }).finally(()=>{
        setLoading(false);
    });
    return payload;
}
