import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import UserCard from './UserCard';
import NewUserCard from './NewUserCard';
import { fetchUsers } from '../../actions';

function DashboardUserManagement(props) {
    useEffect(()=>{
        props.fetchUsers();
    }, []);

    const displayUsers = ()=>{
        return props.user.users.map((user, k)=><UserCard key={k+user["username"]} index={k} isNew={false} profile={user}/>)
    }

    if (props.auth.permission[0]!="t"){
        return <Redirect to={{ 
            pathname: "/dashboard/"
        }}/>
    } else {
        return (
            <div>
                <h1 className='mt-5 h3 text-center text-uppercase'>User Management</h1>

                <div>
                    <div>
                        <h2 className='h4'>Existing Users</h2>
                        {displayUsers()}
                    </div>
                    <hr className='mt-5'/>
                    <div className='mt-5'>
                        <h2 className='h4'>Add New User</h2>
                        <NewUserCard/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {fetchUsers})(DashboardUserManagement);
