import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./PrescriptionHistoryPreview.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LoaderA } from '../../components';
import { fetchPatientPrescriptions } from '../../actions';


function PrescriptionHistoryPreview({auth, ...props}) {
    const [index, setIndex] = useState(0);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [prescriptions, setPrescriptions] = useState([]);
    const [prescription, setPrescription] = useState({});

    useEffect(()=>{
        props.fetchPatientPrescriptions(props.prescriptionHistory.patientHistorySelected);
    }, [props.prescriptionHistory.patientHistorySelected]);

    useEffect(()=>{
        setIndex(0);
        setPrescriptions(props.prescriptionHistory.patientHistory);
        if(props.prescriptionHistory.patientHistory.length===0){
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
            setPrescription(props.prescriptionHistory.patientHistory[0]);
        }
    }, [props.prescriptionHistory.patientHistory]);

    const next = ()=>{
        setPrescription(prescriptions[index-1]);
        setIndex(index-1);
    }
    const previous = ()=>{
        setPrescription(prescriptions[index+1]);
        setIndex(index+1);
    }

    const displayDrugs = ()=>{
        return prescription.drugs.map((drug, i)=>{
            drug = JSON.parse(drug);
            return <tr key={i} className='internal'>
                <td className='nowrap'>{drug[0]}</td>
                <td className='drug'>{drug[1]}</td>
                <td className='nowrap'>{drug[2]}</td>
                <td className='nowrap'>{drug[3]}</td>
                <td className='nowrap'>{drug[4]}</td>
                <td className='nowrap'>{drug[5]}</td>
            </tr>
        });
    }
    const displayCustomDrugs = ()=>{
        return prescription.outsideDrugs.map((drug, i)=>{
            drug = JSON.parse(drug);
            return <tr key={i}>
                <td className='nowrap'>{drug[0]}</td>
                <td className='drug'>{drug[1]}</td>
                <td className='nowrap'>{drug[2]}</td>
                <td className='nowrap'>{drug[3]}</td>
                <td className='nowrap'>{drug[4]}</td>
                <td className='nowrap'>{drug[5]}</td>
            </tr>
        });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Old Prescriptions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='prescription-history-preview'>
                    {isLoading?<LoaderA/>:null}
                    {isEmpty?<div>No previous prescriptions found</div>:
                    <div className='prescription-bg'>
                        <div className={auth.clinicprintformat.charAt(1)=="n"?"prescription-body displayno": "prescription-body"}>
                            <div className='prescription-date'>Date: {prescription.date}</div>

                            <div className='prescription-patient'>
                                {prescription.patienttitle}. {prescription.patientname} - {prescription.patientage}y
                            </div>

                            <div className='previous-reports'>
                                {prescription.reports.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                            </div>

                            <div className='spot-tests'>
                                {prescription.spotTests.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                            </div>

                            <div className='drugs-table'>
                                <table>
                                    <thead></thead>
                                    <tbody>
                                        {displayDrugs()}
                                        {displayCustomDrugs()}
                                    </tbody>
                                </table>
                            </div>

                            <div className='nextvisit'>
                                {prescription.tests.map((text, i)=><div className='text-block' key={i}>{text}</div>)}
                            </div>

                            <div className='note'>
                                {prescription.note}
                            </div>

                            <div className='seal'>
                                <div className='docname'>{prescription.doctorname}</div>
                                <div>{prescription.doctorseal.map((t, i)=><div key={i}>{t}</div>)}</div>
                                <div>Reg. No. {prescription.doctorregno}</div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {prescriptions.length!==0?<div>{index+1} / {prescriptions.length}</div>:null}
                {!isEmpty?<>
                <Button variant="secondary" onClick={previous} disabled={prescriptions.length===index+1}>Previous</Button>
                <Button variant="secondary" onClick={next} disabled={index==0}>Next</Button>
                </>:null}
            </Modal.Footer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        prescriptionHistory: state.prescriptionHistory
    }
}

export default connect(mapStateToProps, {fetchPatientPrescriptions})(PrescriptionHistoryPreview);
