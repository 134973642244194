import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { login, verify, setAuth } from '../../actions';

function Login(props) {
    useEffect(()=>{
        props.setAuth({error: ""});
        if(!props.auth.checked){props.verify();}
    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState(""); 

    if (props.auth.auth) {
        return <Redirect to="/dashboard"/>
    } else {
        return (
            <Container>
                <Row>
                    <Col lg={{span:4, offset: 4}}>
                        <h1>Login Page</h1>
                        <Form onSubmit={(e)=>{e.preventDefault(); props.login(username, password); setPassword(""); setUsername(""); }} validated={false} >
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control required type="username" placeholder="Enter username" value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                                
                            </Form.Group>

                            <Button variant="primary" type="submit">Submit</Button>

                            {props.auth.error!==""? <div className='mt-2'><Alert variant='danger'>{ props.auth.error }</Alert></div> : null}
                            
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps, {login, verify, setAuth})(Login);
