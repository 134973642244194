export const INTAKE_SELECTOR = [
    ["0.25 tab", "1/4 tab"],
    ["0.5 tab", "1/2 tab"],
    ["0.75 tab", "3/4 tab"],
    ["1 tab", "1 tab"],
    ["1.25 tab", "1 1/4 tabs"],
    ["1.5 tab", "1 1/2 tabs"],
    ["1.75 tab", "1 3/4 tabs"],
    ["2 tab", "2 tabs"],
]
