import { SET_USER } from "./types";
import axios from "axios";
import { setLoading, showToast } from "../../App";

export function fetchUsers(){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/auth/fetch_users/")
        .then((res)=>{
            setLoading(false);
            return {users: res.data.users};
        })
        .catch((res)=>{
            setLoading(true);
            return {};
        });

        dispatch({
            type: SET_USER,
            payload: payload,
        });
    }
}

export async function updatePermissionNoRedux(data){
    setLoading(true);
    const payload = await axios.post("/auth/update_permission/", data)
    .then((res)=>{
        showToast({ show_toast: true, toast_message: res.data.info });
        return {...res.data};
    })
    .catch((res)=>{
        showToast({show_toast: true, toast_message: "Internal server error. Please try again later.", show_error: true});
        console.error(res);
        return {error: "Internal server error. Please try again later."};
    }).finally(()=>{
        setLoading(false);
    });
    return payload;
}

export async function resetPasswordNoRedux(data){
    setLoading(true);
    const payload = await axios.post("/auth/reset_password/", data)
    .then((res)=>{
        showToast({ show_toast: true, toast_message: res.data.info });
        return {...res.data};
    })
    .catch((res)=>{
        showToast({show_toast: true, toast_message: "Internal server error. Please try again later.", show_error: true});
        console.error(res);
        return {error: "Internal server error. Please try again later."};
    }).finally(()=>{
        setLoading(false);
    });
    return payload;
}

export async function createUserNoRedux(data){
    setLoading(true);
    const payload = await axios.post("/auth/create_user/", data)
    .then((res)=>{
        if (res.data.error!=undefined){
            showToast({show_toast: true, toast_message: res.data.error, show_error: true});
            return {...res.data};
        } else {
            showToast({ show_toast: true, toast_message: "New user created." });
            return {...res.data, error: ""};
        }
    })
    .catch((res)=>{
        showToast({show_toast: true, toast_message: "Internal server error. Please try again later.", show_error: true});
        console.error(res);
        return {error: "Internal server error. Please try again later."};
    }).finally(()=>{
        setLoading(false);
    });
    return payload;
}

export async function removeUserNoRedux(data){
    setLoading(true);
    const payload = await axios.post("/auth/remove_user/", data)
    .then((res)=>{
        showToast({ show_toast: true, toast_message: "User deleted."});
        return {...res.data};
    })
    .catch((res)=>{
        showToast({show_toast: true, toast_message: "Internal server error. Please try again later.", show_error: true});
        console.error(res);
        return {error: "Internal server error. Please try again later."};
    }).finally(()=>{
        setLoading(false);
    });
    return payload;
}


export function addUserToUsersList(userObj){
    return async function(dispatch, getState) {
        setLoading(true);
        const { user } = getState();
        const newUsers = [...user.users];
        newUsers.push(userObj);
        setLoading(false);

        dispatch({
            type: SET_USER,
            payload: {users: newUsers},
        });
    }
}