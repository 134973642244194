import { SET_INVENTORY } from "./types";
import axios from "axios";
import { setLoading, showToast } from "../../App";
import { store } from "../../App";

export function addDrug(data){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/backend/inventory/drug_add/", data)
        .then((res)=>{
            var infoline = "";
            for (const line in res.data.info){ infoline += res.data.info[line] + "<br/>"; }
            var errorline = "";
            for (const line in res.data.error){ errorline += res.data.errors[line] + "<br/>"; }
            if (errorline!==""){
                showToast({show_toast: true, toast_message: errorline, show_error: true, autohide: false});
            } else {
                showToast({show_toast: true, toast_message: infoline});
            }  
            store.dispatch(getDrugList());
            return {showAddDrug: false};
        })
        .catch((res)=>{
            console.error(res);
            showToast({show_toast: true, toast_message: "Drug insertion incomplete. Please check and verify.", show_error: true});
            return {showAddDrug: false};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function getDrugList(){
    return async function(dispatch) {

        const payload = await axios.get("/backend/inventory/list_drug/")
        .then((res)=>{
            return {drugList: res.data.data};
        })
        .catch((res)=>{
            console.error(res);
            return {};
        })
        .finally(()=>{
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function setInventory(payload){
    return async function(dispatch) {
        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function setEditVariantId(editVariantId, showEditDrug=true){
    return async function(dispatch) {
        dispatch({
            type: SET_INVENTORY,
            payload: {editVariantId: editVariantId, showEditDrug: showEditDrug},
        });
    }
}

export function fetchDrug(variantId){
    return async function(dispatch) {
        setLoading(true);
        const payload = await axios.get("/backend/inventory/fetch_drug/?id="+variantId)
        .then((res)=>{
            return { variant: res.data.variant};
        })
        .catch((res)=>{
            console.error(res);
            return { showEditDrug: false };
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function editDrug(data){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/backend/inventory/drug_edit/", data)
        .then((res)=>{
            var infoline = "";
            for (const line in res.data.info){ infoline += res.data.info[line] + "<br/>"; }
            var errorline = "";
            for (const line in res.data.error){ errorline += res.data.errors[line] + "<br/>"; }
            if (errorline!==""){
                showToast({show_toast: true, toast_message: errorline, show_error: true, autohide: false});
            } else {
                showToast({show_toast: true, toast_message: infoline});
            }  
            store.dispatch(getDrugList());
            return {editVariantId: "", showEditDrug: false};
        })
        .catch((res)=>{
            console.error(res);
            showToast({show_toast: true, toast_message: "Drug update failed. Please check and verify.", show_error: true});
            return {editVariantId: "", showEditDrug: false};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function setRefillVariantId(refillVariantId, showRefill=true){
    return async function(dispatch) {
        dispatch({
            type: SET_INVENTORY,
            payload: {refillVariantId: refillVariantId, showRefill: showRefill},
        });
    }
}

export function fetchRefill(variantId){
    return async function(dispatch) {
        setLoading(true);
        const payload = await axios.get("/backend/inventory/fetch_refill/?id="+variantId)
        .then((res)=>{
            return { refillList: res.data.refillList, refillVariant: res.data.refillVariant};
        })
        .catch((res)=>{
            console.error(res);
            return { showRefill: false };
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}

export function addRefill(data){
    return async function(dispatch) {
        setLoading(true);

        const payload = await axios.post("/backend/inventory/refill_add/", data)
        .then((res)=>{
            var infoline = "";
            for (const line in res.data.info){ infoline += res.data.info[line] + "<br/>"; }
            var errorline = "";
            for (const line in res.data.error){ errorline += res.data.errors[line] + "<br/>"; }
            if (errorline!==""){
                showToast({show_toast: true, toast_message: errorline, show_error: true, autohide: false});
            } else {
                showToast({show_toast: true, toast_message: infoline});
            }  
            store.dispatch(getDrugList());
            return {refillVariantId: "", showRefill: false};
        })
        .catch((res)=>{
            console.error(res);
            showToast({show_toast: true, toast_message: "Refill insertion failed. Please check and verify.", show_error: true});
            return {showRefill: false};
        })
        .finally(()=>{
            setLoading(false);
        });

        dispatch({
            type: SET_INVENTORY,
            payload: payload,
        });
    }
}
