
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import "./DashboardPrescriptionHistoryNav.css";
import { setUI, fetchPrescriptions } from '../../actions';
import questionMark from "../../assets/icons/QuestionMark.svg"; 
import cashDollar from "../../assets/icons/CashDollar.svg"; 
import deleteSVG from "../../assets/icons/Delete.svg"; 
import Right from "../../assets/icons/ArrowRight.svg";
import Left from "../../assets/icons/ArrowLeft.svg";

function DashboardPrescriptionHistoryNav(props) {
    useEffect(()=>{
        props.fetchPrescriptions();
    }, [props.common.patientNo]);

    const newRef = useRef(null);
    const handleOutsideClick = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            if (props.ui.show_patient_history_nav){
                props.setUI({show_side_nav: false, show_patient_history_nav: false});
            }
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    const displayPending = ()=>{
        return props.prescriptionHistory.pending.map((p, i)=><div key={i} className={`entry ${p.status}`}>
            <NavLink exact to={`/dashboard/prescription/${p.id}`} className="nav-link" activeClassName="active">
                <img src={questionMark} alt="Pending"/>
                <span>{p.date}</span>
                <span>{p.name}</span>
            </NavLink>
        </div>);
    }

    const displayOther= ()=>{
        return props.prescriptionHistory.other.map((p, i)=><div key={i} className={`entry ${p.status}`}>
            <NavLink exact to={`/dashboard/prescription/${p.id}`} className="nav-link" activeClassName="active">
                {p.status=="removed"?
                <img src={deleteSVG} alt="Removed"/>
                :
                <img src={cashDollar} alt="Paid"/>
                }
                <span>{p.date}</span>
                <span>{p.name}</span>
            </NavLink>
        </div>);
    }

    if (props.prescriptionHistory.isloading){
        return null
    } else {
        return (
            <div className={props.ui.show_patient_history_nav ? 'dashboard-patient-history-nav show' : 'dashboard-patient-history-nav'} ref={newRef}>
                <button className='toggle' onClick={()=>{props.setUI(
                    {show_patient_history_nav: !props.ui.show_patient_history_nav, show_side_nav: false}
                )}}>
                    { props.ui.show_patient_history_nav ? (
                    <img src={Right} alt="" width="20"/>
                    ):(
                    <img src={Left} alt="" width="20"/>
                    )}
                </button>

                <div className='pt-3 ps-2' style={{overflow: "hidden", maxHeight: "600px"}}>
                    <h2 className='h4 text-center'>Prescription History</h2>
                    
                    <div className='pending'>
                        {displayPending()}
                    </div>
                    <div className='other'>
                        {displayOther()}
                    </div>
                </div> 
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui,
        prescriptionHistory: state.prescriptionHistory,
        common: state.common,
    }
}

export default connect(mapStateToProps, {setUI, fetchPrescriptions})(DashboardPrescriptionHistoryNav);
