
import React, { useState } from 'react';
import { connect } from 'react-redux';
import "./DashboardPrescription.css";
import { buildPrescription, openPrescription } from '../../actions';
import DrugItem from './DrugItem';
import CustomDrugItem from './CustomDrugItem';
import CustomText from './CustomText';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import lock from '../../assets/icons/lock.svg';
import up from '../../assets/icons/CaretUp.svg';
import down from '../../assets/icons/CaretDown.svg';

function DashboardPrescription(props) {
    const [drugs, setDrugs] = useState([]);
    const [customDrugs, setCustomDrugs] = useState([]);
    const [reports, setReports] = useState([]);
    const [spotTests, setSpotTests] = useState([]);
    const [tests, setTests] = useState([]);
    const [note, setNote] = useState("");

    const submit = ()=>{
        props.buildPrescription({
            drugs: drugs,
            customDrugs: customDrugs,
            reports: reports,
            spotTests: spotTests,
            tests: tests,
            note: note
        });
    }

    const handleDrugItem = (data)=>{
        const newDrugs = [...drugs];
        newDrugs[data.drugIndex] = data;
        setDrugs(newDrugs);
    }
    const addDrugItem = ()=>{
        const newDrugs = [...drugs]; newDrugs.push({}); setDrugs(newDrugs);
    }
    const displayDrugItems = ()=>{
        return drugs.map((item, i)=>{ return <DrugItem key={i} drugIndex={i} onChange={handleDrugItem} />});
    }



    const handleCustomDrugItem = (data)=>{
        const newDrugs = [...customDrugs];
        newDrugs[data.drugIndex] = data;
        setCustomDrugs(newDrugs);
    }
    const addCustomDrugItem = ()=>{
        const newDrugs = [...customDrugs]; newDrugs.push({}); setCustomDrugs(newDrugs);
    }
    const displayCustomDrugItems = ()=>{
        return customDrugs.map((item, i)=>{ return <CustomDrugItem which="cdrugs" key={i} drugIndex={i} onChange={handleCustomDrugItem} />});
    }



    const handleReports = (data)=>{
        const newDrugs = [...reports];
        newDrugs[data.drugIndex] = data.customText;
        setReports(newDrugs);
    }
    const addReportitem = ()=>{
        const newDrugs = [...reports]; newDrugs.push(""); setReports(newDrugs);
    }
    const displayReportItems = ()=>{
        return reports.map((item, i)=>{ return <CustomText which="reports" key={i} drugIndex={i} onChange={handleReports} />});
    }



    const handleSpotTests = (data)=>{
        const newDrugs = [...spotTests];
        newDrugs[data.drugIndex] = data.customText;
        setSpotTests(newDrugs);
    }
    const addSpotTestitem = ()=>{
        const newDrugs = [...spotTests]; newDrugs.push(""); setSpotTests(newDrugs);
    }
    const displaySpotTestItems = ()=>{
        return spotTests.map((item, i)=>{ return <CustomText which="spottests" key={i} drugIndex={i} onChange={handleSpotTests} />});
    }



    const handleTests = (data)=>{
        const newDrugs = [...tests];
        newDrugs[data.drugIndex] = data.customText;
        setTests(newDrugs);
    }
    const addTestitem = ()=>{
        const newDrugs = [...tests]; newDrugs.push(""); setTests(newDrugs);
    }
    const displayTestItems = ()=>{
        return tests.map((item, i)=>{ return <CustomText which="tests" key={i} drugIndex={i} onChange={handleTests} />});
    }

    return (
        <div className='dashboard-prescription'>
            <div className='mt-4' >
                <h2 className='h4 bg-light py-2 px-2 mb-0 d-flex justify-content-between according-head'>
                    <div className='d-flex'><span>Prescription</span></div>
                    <div>
                        {props.prescription.isFreeze?
                        <img src={lock} height={30} width={30} alt="lock icon"/>
                        :(
                            !props.prescription.open ? (<img src={down} height={30} width={30} alt="down"/>):(<img src={up} height={30} width={30} alt="up"/>)
                        )}
                    </div>
                </h2>
                { props.prescription.isFreeze ? (
                    <div className='text-primary px-2 border'>
                        Ready
                    </div>
                ): null}
            </div>

            {props.prescription.open?(
            <div className='border border-top-0 py-2 '>
                <div className='sheet'>
                    <div className='pb-4'>
                        <div className='h6'>Previous Reports</div>
                        <div className='d-flex flex-wrap'>
                            {displayReportItems()}
                            <Button variant="secondary" size="sm" onClick={addReportitem}>+</Button>
                        </div>
                    </div>

                    <div className='pb-4'>
                        <div className='h6'>Spot Tests</div>
                        <div className='d-flex flex-wrap'>
                            {displaySpotTestItems()}
                            <Button variant="secondary" size="sm" onClick={addSpotTestitem}>+</Button>
                        </div>
                    </div>

                    <div className='pb-4'>
                        <div className='h6'>Internal Drugs</div>
                        {displayDrugItems()}
                        <Button variant="secondary" size="sm" onClick={addDrugItem}>+</Button>
                    </div>

                    <div className='pb-4'>
                        <div className='h6'>Drugs from Phamacy</div>
                        {displayCustomDrugItems()}
                        <Button variant="secondary" size="sm" onClick={addCustomDrugItem}>+</Button>
                    </div>
                    
                    <div className='pb-4'>
                        <div className='h6'>Tests for next visit</div>
                        <div className='d-flex flex-wrap'>
                            {displayTestItems()}
                            <Button variant="secondary" size="sm" onClick={addTestitem}>+</Button>
                        </div>
                    </div>

                    <div className='pb-4'>
                        <div className='h6'>Note</div>
                        <div>
                            <Form.Group>
                                <Form.Control size="sm" as="textarea" rows={3} type="text" placeholder="" value={note} onChange={(e)=>{setNote(e.target.value)}}/>
                            </Form.Group>
                        </div>
                    </div>

                    <Button variant="primary" onClick={submit}>Build Prescription</Button>
                </div>
            </div>
            ):null}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        prescription: state.prescription
    }
}

export default connect(mapStateToProps, {buildPrescription, openPrescription})(DashboardPrescription);