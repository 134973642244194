import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchPossibleWordsNoRedux } from '../../actions';
import Form from 'react-bootstrap/Form';
import cancel from '../../assets/icons/cancel.svg';


function CustomText(props) {
    const [customText, setCustomText] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const [isValid, setIsValid] = useState(false);

    useEffect(()=>{
        if(checkValidity()){
            props.onChange({drugIndex: props.drugIndex, customText: customText});
        }else{
            props.onChange({drugIndex: props.drugIndex, customText: ""});
        };
    }, [customText]);
    const checkValidity = ()=>{
        if (customText.trim()===""){ setIsValid(false); return false; }
        setIsValid(true); return true;
    }

    useEffect(()=>{
        const delayDebounceFn = setTimeout(async () => {
            const payload = await searchPossibleWordsNoRedux({customText: customText, which: props.which});
            setSuggestions(payload);
        }, 600);
        return ()=> clearTimeout(delayDebounceFn);
    }, [customText]);

    return (
        <div className='custom-text'>
            <div className='d-flex flex-wrap'>
                <Form.Group className='test'>
                    <Form.Control size="sm" list={"suggestion"+props.which+props.drugIndex} type="text" placeholder="" value={customText} onChange={(e)=>{setCustomText(e.target.value)}} required/>
                    <datalist id={"suggestion"+props.which+props.drugIndex}>
                        {suggestions.map((item, i)=><option key={i} value={item}>{item}</option>)}
                    </datalist>
                </Form.Group>
                {isValid?null:<img className='feed' src={cancel} alt="Error" height={"31px"}/>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps, {})(CustomText);