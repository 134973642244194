import React, {useState} from 'react';
import { connect } from 'react-redux';
import "./UserCard.css";
import Profile from "../../assets/icons/Profile.svg";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { createUserNoRedux, addUserToUsersList } from '../../actions';

function NewUserCard(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("ABCD1234#");
    const [role, setRole] = useState("assistant");
    const [permission, setPermission] = useState(["f", "f", "f", "f", "f", "f"])
    const [error, setError] = useState("");

    const handleSubmit = async (e)=>{
        e.preventDefault();
        if(e.target.checkValidity()){
            const result = await createUserNoRedux({
                username: username,
                password: password,
                role: role,
                permission: permission.join(""),
            });
            setError(result.error);
            if (result.error===""){
                // successfull - update users list
                props.addUserToUsersList({
                    id: result.id,
                    username: result.username,
                    role: result.role,
                    permission: result.permission,
                    isDeleted: "Delete User"
                });
                setUsername("");
                setPermission(["f", "f", "f", "f", "f", "f"]);
            }
        }
    }

    const handlePermission = (index)=>{
        const per = [...permission]
        per[index] = `${per[index]==="t"?"f":"t"}`;
        setPermission(per);
    }

    const handleRole = (value)=>{
        if(value!=="doctor"){
            const per = [...permission]
            per[1] = "f";
            setPermission(per);
        }
        setRole(value);
    }

    return (
        <div className='usercard border mb-5 mt-3'>
            <div className='text-center'>
                <img src={Profile} height={100} alt="Profile Icon"/>
            </div>
            <div>
                <div className='px-2'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId={"username"+props.index}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control size="sm" type="text" maxLength={40} required value={username} onChange={(e)=>{setUsername(e.target.value.trim())}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"password"+props.index}>
                            <Form.Label>Temporary Password</Form.Label>
                            <Form.Select size="sm" aria-label="select a password" value={password} onChange={(e)=>{setPassword(e.target.value)}}>
                                <option value="ABCD1234#">ABCD1234#</option>
                                <option value="TEMPORARY@88">TEMPORARY@88</option>
                                <option value="ABCD@1234">ABCD@1234</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={"role"+props.index}>
                            <Form.Label>Role</Form.Label>
                            <Form.Select size="sm" aria-label="select a role" value={role} onChange={(e)=>{handleRole(e.target.value)}}>
                                <option value="assistant">Assistant</option>
                                <option value="doctor">Doctor</option>
                                <option value="manager">Manager</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Permissions</Form.Label>
                            <Form.Check type="checkbox" id={"user"+props.index} label="User Management" checked={permission[0]==="t"} onChange={()=>handlePermission(0)}/>
                            <Form.Check type="checkbox" id={"prescription"+props.index} label="Prescription Writing" checked={permission[1]==="t"} disabled={role!=="doctor"} onChange={()=>handlePermission(1)}/>
                            <Form.Check type="checkbox" id={"inventory"+props.index} label="Inventory" checked={permission[2]==="t"} onChange={()=>handlePermission(2)}/>
                            <Form.Check type="checkbox" id={"billing"+props.index} label="Billing" checked={permission[3]==="t"} onChange={()=>handlePermission(3)}/>
                            <Form.Check type="checkbox" id={"clinic"+props.index} label="Clinic Settings" checked={permission[4]==="t"} onChange={()=>handlePermission(4)}/>
                            <Form.Check type="checkbox" id={"analytics"+props.index} label="Analytics" checked={permission[5]==="t"} onChange={()=>handlePermission(5)}/>
                        </Form.Group>

                        {error!==""?
                            <div className='text-danger pb-2'>{error}</div>
                        :null}

                        <div className='text-center pb-3'>
                            <Button size="sm" variant="primary" type="submit">Create User</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {addUserToUsersList})(NewUserCard);
