import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import "./LoaderA.css";

function loaderA() {
    return <div className="comp_loader">
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(loaderA);