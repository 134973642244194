import { SET_UI } from "./types";

const initState = {
    show_side_nav: false,
    show_patient_history_nav: false, 
};

export default function(state = initState, action) {
  switch (action.type) {
    case SET_UI:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
