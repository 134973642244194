import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchPossibleDrugsNoRedux } from '../../actions';
import { INTAKE_SELECTOR } from "./Constants";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import tick from '../../assets/icons/tick.svg';
import cancel from '../../assets/icons/cancel.svg';


function DrugItem({onChange, drugIndex}) {
    const [route, setRoute] = useState("O");
    const [drugObj, setDrugObj] = useState({});
    const [intake, setIntake] = useState("1 tab");
    const [meal, setMeal] = useState("");
    const [frequency, setFrequency] = useState("1 daily");
    const [pillCount, setPillCount] = useState("");
    const [duration, setDuration] = useState("");
    const [durationUnit, setDurationUnit] = useState("1 days");


    const [isValid, setIsValid] = useState(false);
    const [drug, setDrug] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);
    const [possibleDrugs, setPossibleDrugs] = useState([]);
    const [error, setError] = useState("");

    useEffect(()=>{
        setSearchLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            const payload = await searchPossibleDrugsNoRedux({drug: drug});
            setPossibleDrugs(payload);
            setSearchLoading(false);
        }, 600);
        return ()=> clearTimeout(delayDebounceFn);
    }, [drug]);

    useEffect(()=>{
        setError("");
        if(checkValidity()){
            onChange({
                drugIndex: drugIndex, ...drugObj, 
                route: route, intake: intake, meal: meal, frequency: frequency, 
                pillCount: pillCount, duration: duration, durationUnit: durationUnit, 
                requiredCount: getRequredPillCount()
            });
        }else{
            onChange({
                drugIndex: drugIndex
            });
        };
    }, [route, drugObj, intake, meal, frequency, pillCount, duration, durationUnit]);

    const checkValidity = ()=>{
        if (drugObj.variantId===undefined){ setIsValid(false); return false; }
        if (frequency==="sos" || frequency==="prn"){
            if (pillCount===""){ setIsValid(false); return false; }
        
        }
        if (intake===""){ setIsValid(false); return false; }
        if (duration===""){ setIsValid(false); return false; }

        if (drugObj.available>=getRequredPillCount()){
            setIsValid(true); return true;
        } else {
            setError("Not enough stock."); setIsValid(false); return false;
        }
    }

    const getRequredPillCount = ()=>{
        var requredPillCount = 1;
        if (frequency==="sos" || frequency==="prn"){
            requredPillCount = pillCount;
        } else if (drugObj.nature!="pill"){
            requredPillCount=1;
        } else {
            const intakeCount = intake.split(" ")[0];
            const freq=frequency.split(" ")[0];
            const dayCount = duration * durationUnit.split(" ")[0];
            requredPillCount = dayCount*freq*intakeCount;
        }
        return Math.ceil(requredPillCount);
    }

    const displayPossibleDrugs = ()=>{
        if (possibleDrugs.length===0){ return null; }
        const possibleDrugsEle = [];
        possibleDrugs.forEach((drug, i)=>{
            possibleDrugsEle.push(
                <div key={i}>
                    <button className='btn btn-link text-nowrap' onClick={()=>selectDrug(i)}>{drug.name} {drug.dose!==""?" - "+drug.dose: "("+drug.nature.slice(0,2)+")"}{drug.unit} | Stock: {drug.available}</button>
                </div>
            )
        });
        return possibleDrugsEle;
    }

    const setIntakeSelector = ()=>{
        switch (drugObj.nature) {
            case "pill":
                return <Form.Select size="sm" aria-label="Intake" value={intake} onChange={(e)=>{setIntake(e.target.value)}} required>
                            {INTAKE_SELECTOR.map((option, i)=><option key={i} value={option[0]}>{option[1]}</option>)}
                        </Form.Select>;
            case "syrup":
                return <Form.Control size="sm" type="text" maxLength={8} style={{maxWidth: "100px"}} placeholder="xx ml" value={intake} onChange={(e)=>{setIntake(e.target.value.trim())}} required/>;
            case "drop":
                return <Form.Control size="sm" type="text" maxLength={8} style={{maxWidth: "100px"}} placeholder="xx drops" value={intake} onChange={(e)=>{setIntake(e.target.value.trim())}} required/>;
            case "gel":
                return;
            default:
                return null;
        }
    }

    const selectDrug = (index)=>{
        setSearchLoading(false);
        setDrugObj(possibleDrugs[index]);
        setPossibleDrugs([]);
        switch (possibleDrugs[index].nature) {
            case "pill":
                setIntake("1 tab");
                break;
            case "gel":
                setIntake("gel");
                break;
            default:
                setIntake("");
                break;
        }
    }

    const deselectDrug = ()=>{ setDrug(""); setDrugObj({}); }

    return (
        <div className='drug-item'>
            <div className='d-flex flex-wrap'>
                <Form.Group>
                    <Form.Select size="sm" aria-label="Route" value={route} onChange={(e)=>{setRoute(e.target.value)}} required>
                        <option value="O">O</option>
                        <option value="S/Y">S/Y</option>
                        <option value="S/L">S/L</option>
                        <option value="PR">PR</option>
                        <option value="S/C">S/C</option>
                        <option value="IM">IM</option>
                        <option value="IV">IV</option>
                        <option value="TP">TP</option>
                        <option value="G">G</option>
                        <option value="Neb">Neb</option>
                        <option value="MDI">MDI</option>
                        <option value="DPI">DPI</option>
                    </Form.Select>
                </Form.Group>
                    
                <Form.Group className='position-relative drug-search'>
                    { drugObj.variantId===undefined ?
                    <Form.Control size="sm" type="text" placeholder="Find the drug" value={drug} onChange={(e)=>{setDrug(e.target.value)}}/>
                    :
                    <div className='drug-selected'>
                        <span>
                            {drugObj.name} {drugObj.dose!==""?" - "+drugObj.dose: "("+drugObj.nature.slice(0,2)+")"}{drugObj.unit}
                        </span>
                        <Button variant="light" size="sm" onClick={deselectDrug}>X</Button>
                    </div>
                    }

                    { searchLoading ? 
                    <Spinner className='spinner' animation="border" role="status" size="sm"><span className="visually-hidden">Loading...</span></Spinner>
                    : null}

                    {possibleDrugs.length!==0?
                    <div className='drug-results'>
                        {displayPossibleDrugs()}
                    </div>
                    :null}
                </Form.Group>

                <Form.Group>
                    {setIntakeSelector()}
                </Form.Group>

                <Form.Group>
                    <Form.Select size="sm" aria-label="Meal" value={meal} onChange={(e)=>{setMeal(e.target.value)}} required>
                        <option value="blank"></option>
                        <option value="AC">AC</option>
                        <option value="PO">PO</option>
                    </Form.Select>
                </Form.Group>

                {frequency==="sos" || frequency==="prn" ?
                <Form.Group className='pill-count' style={{paddingRight: "5px"}}>
                    <Form.Control size="sm" type="number" min="1" placeholder="Pills count" value={pillCount} onChange={(e)=>{setPillCount(e.target.value.trim())}} required/>
                </Form.Group>
                :null}

                <Form.Group>
                    <Form.Select size="sm" aria-label="Frequency" value={frequency} onChange={(e)=>{setFrequency(e.target.value)}}>
                        <option value="1 daily">daily</option>
                        <option value="3 tds">tds</option>
                        <option value="2 bd">bd</option>
                        <option value="4 qid">qid</option>
                        <option value="sos">sos</option>
                        <option value="prn">prn</option>
                        <option value="6 q4h">q4h</option>
                        <option value="4 q6h">q6h</option>
                        <option value="3 q8h">q8h</option>
                        <option value="12 q2h">q2h</option>
                        <option value="2 q12h">q12h</option>
                        <option value="0.5 qod">qod</option>
                        <option value="1 qam">qam</option>
                        <option value="1 qpm">qpm</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group style={{marginRight: "-5px", maxWidth: "100px"}}>
                    <Form.Control size="sm" type="number" min="1" placeholder="Duration" value={duration} onChange={(e)=>{setDuration(e.target.value.trim())}} required/>
                </Form.Group>

                <Form.Group>
                    <Form.Select size="sm" aria-label="Duration" value={durationUnit} onChange={(e)=>{setDurationUnit(e.target.value)}} required>
                        <option value="1 days">days</option>
                        <option value="7 weeks">weeks</option>
                        <option value="30 months">months</option>
                    </Form.Select>
                </Form.Group>

                {isValid?<img className='feed' src={tick} alt="Correct" height={"31px"}/>:<img className='feed' src={cancel} alt="Error" height={"31px"}/>}

                <span className='text-danger'>{error}</span>
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps, {})(DrugItem);