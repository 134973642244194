import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import axios from 'axios';
import { Dashboard, Login, Landing, Register, Plans } from "./pages";
import { PrivateRoute, PublicRoute } from "./routes";
import { AuthReducer, CommonReducer, DashboardUIReducer, PatientReducer, InventoryReducer, PrescriptionReducer, setLoading as loading, showToast as toast, PrescriptionHistoryReducer, UserReducer } from "./actions";
import { Loader, ToastM } from "./components";

// Initialize axios
axios.defaults.baseURL = window.config.baseURL;
axios.interceptors.request.use((config)=>{
  config.headers.Authorization = `Bearer ${localStorage.getItem('at')}`;
  return config;
}, (error)=>{ 
  console.error("expired.. log by axios interceptor")
});

// initialize redux store
export const store = configureStore({
  reducer: {
    common: CommonReducer,
    auth: AuthReducer,
    ui: DashboardUIReducer,
    patient: PatientReducer,
    inventory: InventoryReducer,
    prescription: PrescriptionReducer,
    prescriptionHistory: PrescriptionHistoryReducer,
    user: UserReducer,
  },
  devTools: true
});

// Initializing loading controller
export const setLoading = (comp_loading, page_loading)=>{ store.dispatch(loading(comp_loading, page_loading)); }

// Initializing toast controller
export const showToast = (payload)=>{ store.dispatch(toast(payload)); }

function App() {
  return (
    <Provider store={store}>
      <Router>

        <Loader/>

        <Switch>
          <PublicRoute exact path="/"><Landing /></PublicRoute>
          <PublicRoute path="/register"><Register /></PublicRoute>
          <PrivateRoute path="/dashboard"><Dashboard /></PrivateRoute>
          <PublicRoute path="/login"><Login /></PublicRoute>
          <PublicRoute path="/plans"><Plans /></PublicRoute>
        </Switch>

        <ToastM/>

      </Router>
    </Provider>
  );
}

export default App;
