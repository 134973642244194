
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import "./DashboardSideNav.css";
import { setUI } from '../../actions';
import Right from "../../assets/icons/ArrowRight.svg";
import Left from "../../assets/icons/ArrowLeft.svg";

function DashboardSideNav(props) {
    
    const newRef = useRef(null);
    const handleOutsideClick = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            if (props.ui.show_side_nav){
                props.setUI({show_side_nav: false, show_patient_history_nav: false});
            }
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    return (
        <div className={props.ui.show_side_nav ? 'dashboard-side-nav show' : 'dashboard-side-nav'} ref={newRef}>
            <button className='toggle' onClick={()=>{props.setUI(
                {show_side_nav: !props.ui.show_side_nav, show_patient_history_nav: false}
            )}}>
                { !props.ui.show_side_nav ? (
                <img src={Right} alt="" width="20"/>
                ):(
                <img src={Left} alt="" width="20"/>
                )}
            </button>

            <div className='ps-2 d-flex flex-column justify-content-evenly' style={{height: "100%"}}>
                <NavLink exact to="/dashboard/" className="nav-link" activeClassName="active">
                    <h2 className='h4'>Patient Management</h2>
                </NavLink>
                {props.auth.permission[2]==="t"?
                <NavLink to="/dashboard/inventorymanagement" className="nav-link" activeClassName="active">
                    <h2 className='h4'>Inventory Management</h2>
                </NavLink>
                :null}
                <NavLink to="/dashboard/prescriptions" className="nav-link" activeClassName="active">
                    <h2 className='h4'>Prescriptions</h2>
                </NavLink>
                {props.auth.permission[0]==="t"?
                    <NavLink to="/dashboard/usermanagement" className="nav-link" activeClassName="active">
                        <h2 className='h4'>User Management</h2>
                    </NavLink>
                :null}
                {props.auth.permission[5]==="t"?
                <NavLink to="/dashboard/analytics" className="nav-link" activeClassName="active">
                    <h2 className='h4'>Analytics</h2>
                </NavLink>
                :null}
                {props.auth.permission[4]==="t"?
                <NavLink to="/dashboard/clinic" className="nav-link" activeClassName="active">
                    <h2 className='h4'>Settings</h2>
                </NavLink>
                :null}
            </div> 
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {setUI})(DashboardSideNav);